













































































import Vue from "vue";

export default Vue.extend({
  name: "SidebarInput.vue",
  props: {
    bindedStyle: {
      type: String,
    },
    cssClass: {
      type: String,
    },
    combobox: {
      type: Boolean,
      default: () => false,
    },
    comboboxItems: {
      type: Array,
      default: () => null,
    },
    handleColorPickerInput: {
      type: Function,
      default: () => null,
    },
    inputChange: {
      type: Function,
      default: () => null,
    },
    inputColorPick: {
      type: Boolean,
      default: () => false,
    },
    inputModel: {
      type: String,
      default: () => null,
    },
    inputProp: {
      type: Function,
      default: () => null,
    },
    inputText: {
      type: String,
      default: () => "Input",
    },
    inputType: {
      type: String,
      default: () => "Text",
    },
    overflowHidden: {
      type: Boolean,
      default: () => false,
    },
    switchInput: {
      type: Boolean,
      default: () => false,
    },
    textarea: {
      type: Boolean,
      default: () => false,
    },
    valueProp: {
      type: String || Boolean,
      default: () => null,
    },
  },
  methods: {
    selectChangeEvent: function (e: Event) {
      const target = e.target as HTMLTextAreaElement;
      this.inputChange(target ? target.value : e);
    },
  },
});
