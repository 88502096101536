





























import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "AdFontsControl.vue",
  mixins: [bannerMixin],
  methods: {
    openAssetManagerHandler: function () {
      this.updateAssetManager({
        mediaType: "font",
        isModalLibraryOpen: true,
      });
    },
  },
});
