





















import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "OtomoBreadcrumbs",
  mixins: [bannerMixin],
  computed: {
    crumbs: function () {
      let breadcrumbs = [
        {
          path: "clients",
          to: `/clients`,
          text: "Clients",
        },
      ];

      if (this.state.selectedClient?.hashid) {
        breadcrumbs.push({
          path: this.state.selectedClient.hashid,
          to: `/clients/${this.state.selectedClient.hashid}`,
          text: this.state.selectedClient.name,
        });
      }

      if (this.state.campaign?.hashid) {
        breadcrumbs.push({
          path: this.state.campaign.hashid,
          to: `/clients/${this.state.selectedClient.hashid}/campaigns/${this.state.campaign.hashid}/creatives`,
          text: this.state.campaign.name,
        });
      }

      return breadcrumbs;
    },
  },
});
