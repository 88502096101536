






































































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
// import SidebarLock from "./ControlsShapes/SidebarLock.vue";
export default Vue.extend({
  components: {
    SidebarInput,
    // SidebarLock,
  },
  data: () => ({
    backgroundImageSizeOptions: ["contain", "cover"],
    backgroundImageRepeatOptions: [
      "no-repeat",
      "repeat",
      "repeat-x",
      "repeat-y",
    ],
  }),
  methods: {
    handleRotate(rotateZ: number) {
      const currentTransform = this.state.selectedKeyframe.properties.transform;
      const currentTranslate = currentTransform
        .split("translate3d(")[1]
        .split(")")[0]
        .split(", ")
        .map((value: string) => {
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            return `${numericValue}px`;
          } else {
            return value;
          }
        })
        .join(", ");
      this.updateProperties({
        rotateZ,
        transform: `translate3d(${currentTranslate}) rotate(${rotateZ}deg)`,
      });
    },
  },
  name: "ImagepropertiesControl.vue",
  mixins: [bannerMixin],
});
