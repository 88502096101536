



















































































































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import OtomoTableInner from "./OtomoTableInner.vue";
import CreativePreview from "../Creative/CreativePreview.vue";

export default Vue.extend({
  name: "OtomoTable",
  mixins: [bannerMixin],
  components: { OtomoTableInner, CreativePreview },
  data: () => ({
    search: "",
    dialog: false,
    newItemName: "",
    dialogPreview: false,
    selectedPreview: null,
    desserts: [],
    loading: true,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    currentTableParams: {
      hashId: null,
      activePage: 1,
      count: 0,
      pagesCount: 1,
    },
  }),
  props: {
    isDialogAddOpen: {
      type: Boolean,
      default: () => false,
    },
    tableHeaders: {
      type: Array,
    },
    tableItems: {
      type: Array,
    },
    tableItemClick: {
      type: Function,
      default: () => null,
    },
    tableItemsTemplate: {
      type: String,
    },
    tableType: {
      type: String,
    },
    tableName: {
      type: String,
    },
    tableButtons: {
      type: Array,
      default: () => [],
    },
    disablePagination: {
      type: Boolean,
      default: () => false,
    },
    itemsPerPage: {
      type: Function,
      default: () => null,
    },
    tableOptions: {
      type: Object,
      default: () => null,
    },
    tablePage: {
      type: Number,
      default: () => 1,
    },
    serverItemsLength: {
      type: Number,
    },
    tablePagination: {
      type: Function,
      default: () => null,
    },
    sortBy: {
      type: String,
    },
    getNewRequest: {
      type: Function,
      default: () => null,
    },
    closeDialogRemove: {
      type: Function,
      default: () => null,
    },
    addHandler: {
      type: Function,
      default: () => null,
    },
    removeHandler: {
      type: Function,
      default: () => null,
    },
    openDialogRemove: {
      type: Function,
      default: () => null,
    },
    closeDialogAdd: {
      type: Function,
      default: () => null,
    },
    isDialogRemoveOpen: {
      type: Boolean,
      default: () => false,
    },
    isLoadingContent: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogPreview(val) {
      val || this.closePreview();
    },
  },
  // created() {
  //   this.initialize();
  // },
  methods: {
    refreshTablePage: function (page: number) {
      this.currentTableParams.activePage = page;
      this.getNewRequest();
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem({ hashid }: unknown) {
      this.editedIndex = hashid;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteCreative(this.editedIndex);
      this.closeDelete();
      this.refreshTablePage(1);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async addLocalHandler() {
      await this.addHandler(this.newItemName);
      this.newItemName = "";
    },
    openPreview(creativeHashid: string) {
      this.selectedPreview = creativeHashid;
      this.dialogPreview = true;
    },
    closePreview() {
      this.selectedPreview = null;
      this.dialogPreview = false;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
  beforeMount: function () {
    this.clearToOriginalState();
  },
});
