

































import Vue from "vue";
import gsap from "gsap";
// TO-DO: add a new declaration (.d.ts) file containing `declare module 'gsap/Draggable';`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Draggable } from "gsap/Draggable.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import "jquery";
import "jquery-ui-bundle";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "VideoLayer.vue",
  mixins: [bannerMixin],
  components: {},
  props: ["layer", "parentId", "isResizeTool"],
  data() {
    return {
      isLayerDraggable: false,
      layerHeight: "auto",
      layerWidth: "auto",
    };
  },
  methods: {
    dragCanvas: function () {
      const creativeid = this.layer.id;
      const $container = document.getElementById(creativeid);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const componentScope = this;

      //Proxies
      const $right = document.createElement("div");
      const $bottom = document.createElement("div");
      const $top = document.createElement("div");
      const $left = document.createElement("div");
      const mainDraggable = new Draggable(`#${creativeid}`, {
        type: "x,y",
        edgeResistance: 0.65,
        inertia: true,
        zIndexBoost: false,
        onPress: function (e: Event) {
          // gsap.set(mainDraggable, { zIndex: 1 });
          e.stopPropagation();
        },
        onDragEnd: async () => {
          if (
            !this.state.selectedLayer ||
            this.state.selectedLayer.id !== this.layer.id
          ) {
            await this.setSelectedLayer(this.layer);
          }
          const transformValue =
            componentScope.$refs[componentScope.layer.id].style.transform;
          const coordinates = transformValue.split("3d(")[1].split(",");
          const newX = Math.round(coordinates[0].split("px")[0]);
          const newY = Math.round(coordinates[1].split("px")[0]);
          this.updateProperties({
            transform: `translate3d(${newX}, ${newY}, 0)`,
            x: `${newX}`,
            y: `${newY}`,
          });
          endMainDrag;
        },
      });
      let rightLastX = 0;
      let bottomLastY = 0;
      let topLastY = 0;
      let leftLastX = 0;

      new Draggable($right, {
        trigger: `#${creativeid} .right, #${creativeid} .topRight, #${creativeid} .bottomRight`,
        cursor: "e-resize",
        onDrag: updateRight,
        onPress: function () {
          rightLastX = this.x;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      new Draggable($bottom, {
        trigger: `#${creativeid} .bottom, #${creativeid} .bottomRight, #${creativeid} .bottomLeft`,
        cursor: "s-resize",
        onDrag: updateBottom,
        onPress: function () {
          bottomLastY = this.y;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      new Draggable($top, {
        trigger: `#${creativeid} .top, #${creativeid} .topRight, #${creativeid} .topLeft`,
        cursor: "n-resize",
        onDrag: updateTop,
        onPress: function () {
          topLastY = this.y;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      new Draggable($left, {
        trigger: `#${creativeid} .left, #${creativeid} .topLeft, #${creativeid} .bottomLeft`,
        cursor: "w-resize",
        onDrag: updateLeft,
        onPress: function () {
          leftLastX = this.x;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      function endMainDrag() {
        const transformValue =
          componentScope.$refs[componentScope.layer.id].style.transform;
        const coordinates = transformValue.split("3d(")[1].split(",");
        const newX = Math.round(coordinates[0].split("px")[0]);
        const newY = Math.round(coordinates[1].split("px")[0]);
        componentScope.updateProperties({
          transform: `translate3d(${newX}, ${newY}, 0)`,
        });
      }
      function updateTop() {
        const diffY = Math.round(this.y - topLastY);
        gsap.set($container, { height: "-=" + diffY, y: "+=" + diffY });
        topLastY = this.y;
      }
      function updateRight() {
        const diffX = Math.round(this.x - rightLastX);
        gsap.set($container, { width: "+=" + diffX });
        rightLastX = this.x;
      }
      function updateBottom() {
        const diffY = Math.round(this.y - bottomLastY);
        gsap.set($container, { height: "+=" + diffY });
        bottomLastY = this.y;
      }
      function updateLeft() {
        const diffX = Math.round(this.x - leftLastX);
        gsap.set($container, { width: "-=" + diffX, x: "+=" + diffX });
        leftLastX = this.x;
      }

      function updateCreativeSizeState() {
        const layerHeight = gsap.getProperty($container, "height");
        const layerWidth = gsap.getProperty($container, "width");
        const transformValue =
          componentScope.$refs[componentScope.layer.id].style.transform;
        const coordinates = transformValue.split("3d(")[1].split(",");
        const newX = Math.round(coordinates[0].split("px")[0]);
        const newY = Math.round(coordinates[1].split("px")[0]);
        componentScope.updateProperties({
          transform: `translate3d(${newX}, ${newY}, 0)`,
          x: newX,
          y: newY,
        });
        componentScope.updatePropertiesToAllKF({
          height: layerHeight,
          width: layerWidth,
        });
      }
    },
  },
  mounted() {
    if (!this.isResizeTool || !this.layer.isBackground) {
      gsap.registerPlugin(Draggable);
      this.dragCanvas();
    }
  },
});
