

































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
// import SidebarLock from "./ControlsShapes/SidebarLock.vue";

export default Vue.extend({
  name: "AdvanceControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
    // SidebarLock,
  },
  data: () => ({
    isOverflowHidden: false,
    displayItems: ["static", "relative", "absolute"],
    pointOfReferenceItems: [
      "Top-Left",
      "Top-Right",
      "Bottom-Right",
      "Bottom-Left",
      "None",
    ],
  }),
  methods: {
    updateOverflowFlaq: function () {
      this.isOverflowHidden = !this.isOverflowHidden;
      this.updateProperties({
        overflow: this.isOverflowHidden ? `hidden` : `visible`,
      });
    },
    updatePointOfReference: function (value: string) {
      const { rotateZ } = this.state.selectedKeyframe.properties;
      switch (value) {
        case "None":
          this.updateProperties({
            x: 0,
            y: 0,
            rotateZ,
            transform: `translate(0, 0, 0) rotateZ(${rotateZ}deg)`,
            top: "",
            right: "",
            bottom: "",
            left: "",
          });
          break;
        case "Top-Left":
          this.updateProperties({
            x: 0,
            y: 0,
            rotateZ,
            transform: `translate(0, 0, 0) rotateZ(${rotateZ}deg)`,
            top: "0",
            right: "",
            bottom: "",
            left: "0",
          });
          break;
        case "Top-Right":
          this.updateProperties({
            x: 0,
            y: 0,
            rotateZ,
            transform: `translate(0, 0, 0) rotateZ(${rotateZ}deg)`,
            top: "0",
            right: "0",
            bottom: "",
            left: "",
          });
          break;
        case "Bottom-Right":
          this.updateProperties({
            x: 0,
            y: 0,
            rotateZ,
            transform: `translate(0, 0, 0) rotateZ(${rotateZ}deg)`,
            top: "",
            right: "0",
            bottom: "0",
            left: "",
          });
          break;
        case "Bottom-Left":
          this.updateProperties({
            x: 0,
            y: 0,
            rotateZ,
            transform: `translate(0, 0, 0) rotateZ(${rotateZ}deg)`,
            top: "",
            right: "",
            bottom: "0",
            left: "0",
          });
          break;

        default:
          break;
      }
    },
    getPointOfReferenceValue: function () {
      const positioningProps = this.state.selectedKeyframe.properties;

      if (
        (positioningProps.top === "" &&
          positioningProps.right === "" &&
          positioningProps.bottom === "" &&
          positioningProps.left === "") ||
        (positioningProps.top === undefined &&
          positioningProps.right === undefined &&
          positioningProps.bottom === undefined &&
          positioningProps.left === undefined)
      ) {
        return "None";
      }

      if (
        positioningProps.top === "0" &&
        positioningProps.right === "" &&
        positioningProps.bottom === "" &&
        positioningProps.left === "0"
      ) {
        return "Top-Left";
      }

      if (
        positioningProps.top === "0" &&
        positioningProps.right === "0" &&
        positioningProps.bottom === "" &&
        positioningProps.left === ""
      ) {
        return "Top-Right";
      }

      if (
        positioningProps.top === "" &&
        positioningProps.right === "0" &&
        positioningProps.bottom === "0" &&
        positioningProps.left === ""
      ) {
        return "Bottom-Right";
      }

      if (
        positioningProps.top === "" &&
        positioningProps.right === "" &&
        positioningProps.bottom === "0" &&
        positioningProps.left === "0"
      ) {
        return "Bottom-Left";
      }

      return "None";
    },
  },
});
