





























































































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { Layer } from "@/typings/Editors";
import { getLayerLastKF } from "@/utils/layersAndPropsHandling";
import LayerIcon from "@/components/Editor/LayerIcon.vue";

export default Vue.extend({
  name: "LayerItem.vue",
  mixins: [bannerMixin],
  components: { LayerIcon },
  data: () => ({}),
  computed: {
    isDisabled() {
      return (
        this.state.selectedKeyframe && this.state.selectedKeyframe.isAnimated
      );
    },
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
    layer: {
      type: Object,
    },
    color: {
      type: String,
      default: "#FFF",
    },
    active: {
      type: Boolean,
      default: false,
    },
    layerItemClicked: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    getLastKF: function (layer: Layer) {
      return getLayerLastKF(layer);
    },
    lockLayer: function (layer: Layer) {
      this.updateLayer({ ...layer, locked: !this.layer.locked });
    },
    toggleVisibility: function (layer: Layer) {
      this.updateLayer({ ...layer, hidden: !this.layer.hidden });
    },
    toggleIsAllKeyframeUpdate: function (layer: Layer) {
      this.updateLayer({
        ...layer,
        isAllKeyframeUpdate: !this.layer.isAllKeyframeUpdate,
      });
    },
    deleteButtonHandler: function () {
      this.deleteLayer(this.layer.id);
    },
    animateSelectedKeyframe: function (isAnimated: boolean) {
      this.updateKeyframe({
        ...this.state.selectedKeyframe,
        isAnimated,
        duration: 2,
      });
    },
    duplicateLayerItem: function (layer: Layer) {
      this.duplicateLayer(layer);
    },
  },
});
