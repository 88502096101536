






































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { userMixin } from "@/mixins/userMixin";

export default Vue.extend({
  name: "OtomoHeader",
  mixins: [bannerMixin, userMixin],
  // data: () => ({
  //   userName: this.state.user.userName,
  // }),

  props: {
    isEditorPage: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    otomoLogout: function () {
      localStorage.removeItem("otomo_tk");
      this.$router.push({
        name: "Home",
      });
    },
    loadProfile: async function () {
      await this.getProfile();
    },
  },
  mounted() {
    this.loadProfile();
  },
});
