



















import Vue from "vue";
import OtomoHeader from "@/components/Header/Header.vue";
import OtomoBreadcrumbs from "@/components/OtomoBreadcrumbs/OtomoBreadcrumbs.vue";
import OtomoFooter from "@/components/Footer/Footer.vue";
import OtomoToast from "@/components/OtomoToast/OtomoToast.vue";
import "./styles/global.scss";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";

export default Vue.extend({
  name: "App",
  components: {
    OtomoBreadcrumbs,
    OtomoFooter,
    OtomoHeader,
    OtomoToast,
  },
  computed: {
    isEditorPage() {
      return (
        this.$route.name == "Editor" ||
        this.$route.name == "CreativeUpdate" ||
        this.$route.name == "ResizeTool"
      );
    },
    showBreadcrumbs() {
      if (
        this.$route.name == "Home" ||
        this.$route.name == "Editor" ||
        this.$route.name == "CreativeUpdate" ||
        // PENDING FEATURE
        this.$route.name == "ResizeTool"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
});
