
















import Vue from "vue";
import gsap from "gsap";
import Toolbar from "@/components/Editor/Toolbar.vue";
import OtomoCanvas from "@/components/Editor/OtomoCanvas.vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { BannerProps, OtomoCanvasState } from "@/typings/Editors";
import { rebuildCanvasAnimation } from "@/utils/layersAndPropsHandling";
import { CANVAS_ID } from "@/typings/Enums";

export default Vue.extend({
  name: "ResizeTool",
  components: { OtomoCanvas, Toolbar },
  mixins: [bannerMixin],
  data() {
    return {
      theTimeline: gsap.timeline(),
      client: null,
      campaign: null,
      bannerToResize: null,
      isLoading: true,
    };
  },
  computed: {
    isAnimating() {
      return this.getMode;
    },
  },
  methods: {
    async loadState() {
      this.clearEditorState();
      this.client = this.$route.params.clientid;
      this.campaign = this.$route.params.campaignid;
      const creativeHashid = this.$route.params.creativeid;
      if (creativeHashid) await this.getCreative(creativeHashid);
      this.isLoading = false;
    },
  },
  beforeMount: async function () {
    await this.loadState();
  },
  mounted() {
    this.$store.watch(
      (state: OtomoCanvasState) => state.banner,
      (value: BannerProps) => {
        this.bannerToResize = value;
        console.log("RESIZE TOOL state.banner watch");
        gsap.set(CANVAS_ID, { ...value.properties, overflow: "hidden" });
        this.theTimeline = rebuildCanvasAnimation(value.layers);
        this.theTimeline.progress(0);
      }
    );
  },
});
