




























































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
// import SidebarLock from "./ControlsShapes/SidebarLock.vue";

export default Vue.extend({
  components: {
    SidebarInput,
    // SidebarLock,
  },
  data: () => ({
    videoFitSize: ["contain", "cover", "fill"],
    videoPosition: ["top", "bottom", "left", "right", "center"],
  }),
  name: "VideoPropertiesControl.vue",
  mixins: [bannerMixin],
});
