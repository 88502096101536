import gsap from "gsap";
import {
  OtomoCanvasState,
  Layer,
  CSSProperties,
  KeyframeProp,
} from "@/typings/Editors";
import { CANVAS_ID, LayerType } from "@/typings/Enums";
import {
  applyChangesToAllKeyframes,
  applyChangesToEveryKF,
} from "./resizeToolCore";

const DEFAULT_PROPS = {
  fontSize: "12px",
  width: "auto",
  height: "auto",
  transform: `translate(0, 0, 0)`,
};

export const getDefaultImageProps = (initialProps = {}): KeyframeProp => ({
  ...getNewKeyframe(0, {
    ...initialProps,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    display: "inline-block",
    height: "100%",
    opacity: 1,
    position: "absolute",
    width: "100%",
    x: 0,
    y: 0,
    zIndex: 1,
  }),
});

export const getInitialFolderValues = (
  name = `folder-${new Date().getTime()}`
): Layer => {
  return {
    name,
    type: LayerType.Folder,
    text: "Folder",
    id: `folder-${new Date().getTime()}`,
    hidden: false,
    locked: false,
    isAllKeyframeUpdate: false,
    value: "",
    properties: [
      {
        duration: 0,
        id: `prop-set-${new Date().getTime()}`,
        properties: {
          zIndex: 1,
          opacity: 1,
          display: "flex",
          height: 200,
          position: "absolute",
          width: 200,
          flexDirection: "column",
          x: 20,
          y: 20,
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
        },
        repeat: 0,
        startTime: 0,
      },
    ],
    children: [],
  };
};

export const getNewKeyframe = (
  startTime = 0,
  properties: CSSProperties = DEFAULT_PROPS
): KeyframeProp => ({
  id: `prop-set-${new Date().getTime()}`,
  isAnimated: false,
  duration: 0,
  repeat: 0,
  startTime,
  properties,
});

export const getLayerLastKF = (layer: Layer): KeyframeProp | undefined => {
  let lastKF = layer.properties[0];
  if (lastKF) {
    layer.properties.forEach((kf) => {
      if (lastKF.startTime < kf.startTime) lastKF = kf;
    });
  } else {
    return;
  }
  return lastKF;
};

export const updateLayerPropertiesToChildLayer = (
  parentList: Layer[],
  updatedLayer: Layer
): Layer[] => {
  if (!parentList || !parentList.length) return parentList;
  let updatedLayers: Array<Layer> = [...parentList];
  const layerIndex = updatedLayers.findIndex((l) => l.id === updatedLayer.id);

  if (layerIndex !== -1) {
    updatedLayers[layerIndex] = updatedLayer;
  } else {
    updatedLayers = updatedLayers.map((l: Layer) => {
      l.children = updateLayerPropertiesToChildLayer(l.children, updatedLayer);
      return l;
    });
  }

  return updatedLayers;
};

export const duplicateKeyframe = (
  keyframe: KeyframeProp,
  newLayerId: string
): KeyframeProp => {
  const properties = { ...keyframe.properties };
  const newKeyframe = {
    ...keyframe,
    id: `prop-set-${Math.round(Math.random() * Date.now())}`,
    layerId: newLayerId,
    properties,
  };
  return newKeyframe;
};

export const duplicateLayer = (
  state: OtomoCanvasState,
  layer: Layer
): OtomoCanvasState => {
  const layers = [...state.banner.layers];
  const layerIndex = layers.findIndex((l) => l.id === layer.id);
  const layerType = layer.id.split("-")[0];
  const newLayerId = `${layerType}-${Math.round(Math.random() * Date.now())}`;
  const properties = layer.properties.map((kf) =>
    duplicateKeyframe(kf, newLayerId)
  );
  const newLayer = {
    ...layer,
    id: newLayerId,
    name: `${layer.name}-copy`,
    properties,
  };
  layers.splice(layerIndex + 1, 0, newLayer);

  state.banner.layers = layers;
  state.selectedLayer = layers[layerIndex + 1];
  if (properties.length > 0)
    state.selectedKeyframe = properties[properties.length - 1];

  return state;
};

export const updateLayerProps = (
  state: OtomoCanvasState,
  layer: Layer
): OtomoCanvasState => {
  // const layers: Array<Layer> = [...state.banner.layers];
  // const layerIndex = layers.findIndex((l) => l.id === layer.id);
  // layers[layerIndex] = layer;

  // state.banner.layers = layers;
  state.banner.layers = updateLayerPropertiesToChildLayer(
    state.banner.layers,
    layer
  );
  state.selectedLayer = layer;

  return state;
};

export const updatePropertiesToAllKeyframe = (
  state: OtomoCanvasState,
  props: CSSProperties
): OtomoCanvasState => {
  if (!state.selectedLayer || !state.selectedKeyframe) return state;
  const updatedLayer = { ...applyChangesToEveryKF(state.selectedLayer, props) };
  const keyframeIndex = updatedLayer.properties.findIndex(
    (t) => t.id === state.selectedKeyframe?.id
  );
  state.selectedKeyframe = updatedLayer.properties[keyframeIndex];
  state.selectedLayer = updatedLayer;
  const theLayers = updateLayerPropertiesToChildLayer(
    state.banner.layers,
    updatedLayer
  );
  state.banner.layers = [...theLayers];
  return state;
};

export const updatePropertiesToKeyframe = (
  state: OtomoCanvasState,
  props: CSSProperties
): OtomoCanvasState => {
  if (!state.selectedLayer || !state.selectedKeyframe) return state;
  const selectedLayer = state.selectedLayer;
  const keyframeList = selectedLayer.properties;
  let keyframeToChange = state.selectedKeyframe;
  let keyframeIndex = keyframeList.findIndex(
    (t) => t.id === keyframeToChange.id
  );
  let updatedLayer;
  console.log(selectedLayer, "selectedLayerselectedLayer");
  if (selectedLayer.isAllKeyframeUpdate) {
    updatedLayer = applyChangesToAllKeyframes(
      state.selectedLayer,
      state.selectedKeyframe,
      props
    );
    state.selectedKeyframe = { ...updatedLayer.properties[keyframeIndex] };
  } else {
    if (keyframeIndex < 0) {
      keyframeIndex = state.selectedLayer.properties.length - 1;
      keyframeToChange = state.selectedLayer.properties[keyframeIndex];
    }

    keyframeList[keyframeIndex] = {
      ...keyframeToChange,
      properties: { ...keyframeToChange.properties, ...props },
    };

    updatedLayer = {
      ...selectedLayer,
      properties: keyframeList,
    };
    state.selectedKeyframe = { ...keyframeList[keyframeIndex] };
    console.log(
      state.selectedKeyframe,
      "else state.selectedKeyframestate.selectedKeyframe"
    );
  }

  const theLayers = updateLayerPropertiesToChildLayer(
    state.banner.layers,
    updatedLayer
  );
  state.selectedLayer = { ...updatedLayer };
  state.banner.layers = [...theLayers];
  console.log(state.banner.layers, "state.banner.layersstate.banner.layers");

  return state;
};

export const createKeyframe = (
  state: OtomoCanvasState,
  layer: Layer
): OtomoCanvasState => {
  if (!layer) return state;
  const keyframeList = layer.properties;
  const latestKeyframe = getLayerLastKF(layer); // keyframeList[keyframeList.length - 1];
  const startTime = latestKeyframe
    ? latestKeyframe.startTime + latestKeyframe.duration
    : 0;
  const initialProps = latestKeyframe ? latestKeyframe.properties : {};
  const newKeyframe = getNewKeyframe(startTime + 1, initialProps);
  newKeyframe.layerId = layer.id;
  const updatedLayer = { ...layer, properties: [...keyframeList, newKeyframe] };

  const layers: Array<Layer> = [...state.banner.layers];
  const layerIndex = layers.findIndex((l) => l.id === layer.id);
  layers[layerIndex] = updatedLayer;

  // state.banner.layers = layers;
  state.selectedLayer = updatedLayer;
  state.selectedKeyframe = newKeyframe;
  state.banner.layers = updateLayerPropertiesToChildLayer(
    state.banner.layers,
    updatedLayer
  );

  return state;
};

export const updateKeyframe = (
  state: OtomoCanvasState,
  keyframe: KeyframeProp,
  presetLayer?: Layer
): OtomoCanvasState => {
  const selectedLayer = presetLayer ? presetLayer : state.selectedLayer;

  if (!keyframe) return state;
  if (!selectedLayer) return state;

  const keyframeList: KeyframeProp[] = [...selectedLayer.properties];
  const keyframeId = keyframeList.findIndex((t) => t.id === keyframe.id);
  keyframeList[keyframeId] = keyframe;

  const updatedLayer = {
    ...selectedLayer,
    properties: keyframeList,
  };

  const layers: Array<Layer> = [...state.banner.layers];
  const layerIndex = layers.findIndex((l) => l.id === selectedLayer.id);
  layers[layerIndex] = updatedLayer;

  // state.banner.layers = layers;
  state.banner.layers = updateLayerPropertiesToChildLayer(
    state.banner.layers,
    updatedLayer
  );

  state.selectedKeyframe = keyframe;
  return state;
};

export const updateHoverState = (
  state: OtomoCanvasState,
  hoverProperties: KeyframeProp
): OtomoCanvasState => {
  if (state.selectedLayer) {
    const layers = state.banner.layers;
    const layerIndex = layers.findIndex(
      (l) => l.id === state.selectedLayer?.id
    );

    const hoverProps = {
      ...state.selectedLayer.hoverProperties,
      ...hoverProperties,
      properties: {
        ...state.selectedLayer.hoverProperties?.properties,
        ...hoverProperties.properties,
      },
    };

    const updatedLayer = {
      ...state.selectedLayer,
      hoverProperties: hoverProps,
    };
    layers[layerIndex] = updatedLayer;

    state.banner.layers = layers;
    state.selectedLayer = { ...updatedLayer };
  }
  return state;
};

export const animateKeyframes = (
  animatedCanvas: GSAPTimeline,
  layers: Layer[]
): void => {
  animatedCanvas.set(
    [CANVAS_ID],
    { autoAlpha: 1, force3D: true, rotation: 0.001 },
    0
  );
  layers.forEach((layer) => {
    if (!layer.hidden) {
      if (layer.hoverProperties) {
        const layerElement = document.querySelector(`#${layer.id}`);
        const hover = gsap.to(layerElement, {
          paused: true,
          ...layer.hoverProperties,
          ...layer.hoverProperties.properties,
        });
        const playHover = () => hover.play();
        const reverseHover = () => hover.reverse();

        layerElement?.removeEventListener("mouseenter", playHover);
        layerElement?.removeEventListener("mouseleave", reverseHover);
        layerElement?.addEventListener("mouseenter", playHover);
        layerElement?.addEventListener("mouseleave", reverseHover);
      }
      layer.properties.forEach((keyframe) => {
        if (keyframe.isAnimated) {
          animatedCanvas.to(
            `#${layer.id}`,
            {
              ...keyframe.properties,
              duration: keyframe.duration,
              repeat: keyframe.repeat,
            },
            keyframe.startTime
          );
        } else {
          animatedCanvas.set(
            `#${layer.id}`,
            {
              ...keyframe.properties,
              duration: keyframe.duration,
              repeat: keyframe.repeat,
            },
            keyframe.startTime
          );
        }
      });
    }
    if (layer.type === "Folder")
      animateKeyframes(animatedCanvas, layer.children);
  });
};

export const rebuildCanvasAnimation = (
  layers: Layer[],
  currentProgress = 1
): gsap.core.Timeline => {
  const animatedCanvas = gsap.timeline();
  setTimeout(() => {
    animateKeyframes(animatedCanvas, layers);
    animatedCanvas.progress(1);
    animatedCanvas.progress(currentProgress);
    animatedCanvas.pause();
  }, 100);
  return animatedCanvas;
};

export const getKeframesList = (layers: Layer[]): KeyframeProp[] =>
  getLayersList(layers).reduce(
    (acum: KeyframeProp[], l) => [
      ...acum,
      ...(l.properties.map((p: KeyframeProp) => ({
        ...p,
        layerId: l.id,
      })) as KeyframeProp[]),
    ],
    []
  );

export const getLayersList = (layers: Layer[]): Layer[] => {
  let layersList: Layer[] = [];
  layers.map((l) => {
    layersList = [...layersList, l];
    if (l.type === "Folder")
      layersList = [...layersList, ...getLayersList([...l.children])];
  });
  return layersList;
};

// export const loadFontsInCSS = (state: OtomoCanvasState) => {

// };
export const updateLayerListTree = (
  oldState: OtomoCanvasState,
  layers: Layer[]
): OtomoCanvasState => {
  let state = { ...oldState };
  state.banner.layers = layers;

  console.log("1 state: ", state.banner.layers);

  const allLayers = getLayersList(state.banner.layers);

  allLayers.forEach((l) => {
    if (l.type === "Folder") {
      // All folders children must be position static based on the initial requirement
      // They later can change to be what ever so this will be updated here
      l.children.forEach((childLayer) => {
        childLayer.properties.forEach((k) => {
          state = updateKeyframe(
            state,
            {
              ...k,
              properties: {
                ...k.properties,
                position: "static",
                x: 0,
                y: 0,
                transform: "translate3d(0, 0, 0) rotate(0)",
              },
            },
            childLayer
          );
        });
      });
    }
  });

  state.banner.layers.forEach((l) => {
    // all first level layers must be absolute or relative
    l.properties.forEach((kf) => {
      if (kf.properties.position === "static") {
        state = updateKeyframe(
          state,
          {
            ...kf,
            properties: { ...kf.properties, position: "absolute" },
          },
          l
        );
      }
    });
  });

  const { selectedLayer, selectedKeyframe } = getSelectedLayerAndKeyframe(
    layers,
    oldState.selectedLayer?.id,
    oldState.selectedKeyframe?.id
  );

  state.selectedLayer = selectedLayer;
  state.selectedKeyframe = selectedKeyframe;
  return state;
};

const getSelectedLayerAndKeyframe = (
  layers: Layer[] = [],
  selectedLayerId?: string,
  selectedKeyframeId?: string
) => {
  let selectedLayer: Layer | undefined;
  let selectedKeyframe: KeyframeProp | undefined;

  layers.every((layer) => {
    selectedKeyframe = getSelectedKeyframe(layer, selectedKeyframeId);
    if (layer.id === selectedLayerId) selectedLayer = layer;

    if (layer.type === "Folder") {
      const result = getSelectedLayerAndKeyframe(
        layer.children,
        selectedLayerId,
        selectedKeyframeId
      );
      selectedLayer = result.selectedLayer;
      selectedKeyframe = result.selectedKeyframe;
    }
    if (selectedLayer && selectedKeyframe) return false;
  });

  return { selectedLayer, selectedKeyframe };
};

export const deleteLayerDeep = (
  layers: Layer[] = [],
  layerId: string
): Layer[] => {
  let filteredLayers = [...layers.filter((l) => l.id !== layerId)];
  if (filteredLayers.length === layers.length) {
    filteredLayers = filteredLayers.map((layer) => {
      if (layer.type === "Folder")
        layer.children = [...deleteLayerDeep(layer.children, layerId)];
      return layer;
    });
  }
  return filteredLayers;
};

export const deleteKeyframeDeep = (
  layers: Layer[] = [],
  keyframe: KeyframeProp
): Layer[] => {
  if (keyframe.layerId === undefined) return layers;
  const layerIndex = layers.findIndex((l) => l.id === keyframe.layerId);
  if (layerIndex !== -1) {
    const layer = { ...layers[layerIndex] };
    const filteredKeyframes = layer.properties.filter(
      (kf) => kf.id !== keyframe.id
    );
    const newLayers = [...layers];

    layer.properties = [...filteredKeyframes];
    newLayers[layerIndex] = { ...layer };

    return newLayers;
  }

  return layers.map((layer) => {
    if (layer.type === "Folder")
      layer.children = [...deleteKeyframeDeep(layer.children, keyframe)];
    return { ...layer };
  });
};

const getSelectedKeyframe = (layer: Layer, selectedKeyframeId?: string) => {
  let selectedKeyframe: KeyframeProp | undefined;
  layer.properties.every((keyframe) => {
    console.log("keyframe.id", keyframe.id);
    if (keyframe.id === selectedKeyframeId) {
      selectedKeyframe = keyframe;
    }
    if (selectedKeyframe) return false;
  });
  return selectedKeyframe;
};
