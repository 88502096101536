import { Size } from "@/typings/Editors";

export const CANVAS_ID = "#gsap_canvas";
export const PREVIEW_ID = "#gsap_preview";

export enum LayerType {
  Frame = "Frame",
  Div = "Div",
  DivMultiEle = "DivMultiEle",
  Folder = "Folder",
  Shape = "Shape",
  Text = "Text",
  Cta = "Cta",
  Image = "Image",
  Video = "Video",
  Temporal = "Temporal",
}

export enum NotificationTypes {
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export const NotificationTypeValues = {
  success: { color: "green" },
  error: { color: "red" },
  warning: { color: "yellow" },
};

export const RESIZE_OPTIONS: Size[] = [
  { header: "Common Banner Sizes" },
  { header: "Skyscraper" },
  // { divider: true },
  { width: 120, height: 600 },
  { width: 160, height: 600 },
  { width: 300, height: 600 },
  { header: "MPU" },
  // { divider: true },
  { width: 250, height: 250 },
  { width: 300, height: 250 },
  { header: "Leaderboard" },
  // { divider: true },
  { width: 468, height: 60 },
  { width: 728, height: 90 },
  { width: 970, height: 90 },
  { width: 970, height: 250 },
  { header: "Mobile" },
  // { divider: true },
  { width: 300, height: 50 },
  { width: 320, height: 50 },
  { header: "Common Social Sizes" },
  { header: "Instagram" },
  // { divider: true },
  { width: 320, height: 320 },
  { width: 1080, height: 566 },
  { width: 1080, height: 1350 },
  { width: 1080, height: 1080 },
  { width: 1080, height: 1920 },
  { header: "Facebook" },
  // { divider: true },
  { width: 170, height: 170 },
  { width: 1200, height: 630 },
  { width: 630, height: 1200 },
  { width: 1080, height: 1920 },
  { width: 851, height: 315 },
  { header: "Twitter" },
  // { divider: true },
  { width: 400, height: 400 },
  { width: 1024, height: 512 },
  { width: 1500, height: 1500 },
  { width: 1080, height: 1920 },
  { header: "LinkedIn" },
  // { divider: true },
  { width: 400, height: 400 },
  { width: 1200, height: 627 },
  { width: 627, height: 1200 },
  { width: 1128, height: 191 },
];
