

























import Vue from "vue";
import { OtomoCanvasState, ToastNotification } from "@/typings/Editors";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "OtomoToast",
  mixins: [bannerMixin],
  computed: {
    toastColor() {
      return this.state.toastNotification
        ? this.state.toastNotification.type
        : null;
    },
  },
  data: () => ({
    snackbar: false,
    text: `[FontName] added to [CampaignName]`,
  }),
  methods: {
    hideSnackbar: function () {
      this.setOtomoToastValues(null);
    },
    cleanSnackbar: function () {
      const snackBarTimeOut = this.state.toastNotification.timeout
        ? this.state.toastNotification.timeout
        : 4000;
      setTimeout(() => {
        this.hideSnackbar();
      }, snackBarTimeOut);
    },
  },
  mounted() {
    this.$store.watch(
      (state: OtomoCanvasState) => state.toastNotification,
      (value: ToastNotification) => (this.snackbar = value)
    );
  },
});
