





























import Vue from "vue";
import gsap from "gsap";
// TO-DO:  add a new declaration (.d.ts) file containing `declare module 'gsap/Draggable';`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Draggable } from "gsap/Draggable.js";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "ImageLayer.vue",
  mixins: [bannerMixin],
  components: {},
  props: ["layer", "isResizeTool"],
  methods: {
    ////////////////////////////////////////////////////
    // This is to make layers to be resizeable via gsap
    //
    // renderHandle: function () {
    //   const layerId = this.layer.id;
    //   const layerRef = this.$refs[`${layerId}`];
    //   const layerHandleRef = this.$refs[`${layerId}-handle`];
    //   const proxy = document.createElement("div");
    //
    //   Draggable.create(proxy, {
    //     type: "x,y",
    //     onPress: function (e: Event) {
    //       e.stopPropagation();
    //     },
    //     trigger: layerHandleRef,
    //     onDrag: () => {
    //       const handleAxis = layerHandleRef?.getBoundingClientRect();
    //       const layerAxis = layerRef?.getBoundingClientRect();
    //       const x = handleAxis.x + this.x;
    //       const y = handleAxis.y + this.y;
    //       console.log("layer", layerAxis);
    //       console.log("handle", this);
    //       if (x > 200) {
    //         gsap.set(layerRef, { width: x });
    //         gsap.set(layerHandleRef, { x });
    //       }
    //       if (y > 200) {
    //         gsap.set(layerRef, { height: y });
    //         gsap.set(layerHandleRef, { y });
    //       }
    //       this.dragCanvas();
    //     },
    //   });
    // },
    ////////////////////////////////////////////////////
    // dragCanvas: function () {
    //   const layerId = this.layer.id;
    ////////////////////////////////////////////////////
    // This is to make layers to be resizeable via gsap
    //
    // const layerRef = this.$refs[`${layerId}`];
    // const layerHandleRef = this.$refs[`${layerId}-handle`];
    // const { width = 0, height = 0 } = layerRef?.getBoundingClientRect();
    // const { width: handleWidth = 0, height: handleheight = 0 } =
    //   layerHandleRef?.getBoundingClientRect();
    //
    // gsap.set(layerHandleRef, {
    //   x: width - handleWidth,
    //   y: height - handleheight,
    // });
    //
    // this.renderHandle();
    ////////////////////////////////////////////////////
    // Draggable.create(`#${layerId}`, {
    //   type: "x,y",
    //   edgeResistance: 0.65,
    //   // bounds: CANVAS_ID,
    //   inertia: true,
    //   onDragEnd: async () => {
    //     if (
    //       !this.state.selectedLayer ||
    //       this.state.selectedLayer.id !== this.layer.id
    //     ) {
    //       await this.setSelectedLayer(this.layer);
    //     }
    //     // const layerRef = this.$refs[`${layerId}`];
    //     // const { x, y } = layerRef?.getBoundingClientRect();
    //     // this.updateProperties({
    //     //   transform: `translate3d(${x}px, ${y}px)`,
    //     // });
    //     // To-Do:
    //     // splitTransform then updateProp:
    //     this.updateProperties({
    //       transform: this.$refs[this.layer.id].style.transform,
    //     });
    //   },
    // });
    // },
    dragCanvas: function () {
      const creativeid = this.layer.id;
      const $container = document.getElementById(creativeid);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const componentScope = this;
      componentScope.updatePropertiesToAllKF({
        height: `90%`,
        width: `90%`,
        transform: `translate(-2%, -2%)`,
        top: `2%`,
        left: `2%`,
      });
      //Proxies
      const $right = document.createElement("div");
      const $bottom = document.createElement("div");
      const $top = document.createElement("div");
      const $left = document.createElement("div");
      const mainDraggable = new Draggable(`#${creativeid}`, {
        type: "x,y",
        edgeResistance: 0.65,
        // bounds: CANVAS_ID,
        inertia: true,
        zIndexBoost: false,
        onPress: function (e: Event) {
          // gsap.set(mainDraggable, { zIndex: 1 });
          e.stopPropagation();
        },
        onDragEnd: async () => {
          if (
            !this.state.selectedLayer ||
            this.state.selectedLayer.id !== this.layer.id
          ) {
            await this.setSelectedLayer(this.layer);
          }
          // const layerRef = this.$refs[`${layerId}`];
          // const { x, y } = layerRef?.getBoundingClientRect();

          // this.updateProperties({
          //   transform: `translate3d(${x}px, ${y}px)`,
          // });
          // To-Do:
          // splitTransform then updateProp:
          const transformValue =
            componentScope.$refs[componentScope.layer.id].style.transform;
          const coordinates = transformValue.split("3d(")[1].split(",");
          const newX = Math.round(coordinates[0].split("px")[0]);
          const newY = Math.round(coordinates[1].split("px")[0]);
          this.updateProperties({
            transform: `translate3d(${newX}, ${newY}, 0)`,
            x: `${newX}`,
            y: `${newY}`,
            top: `2%`,
            left: `2%`,
          });

          // gsap.set(mainDraggable, { zIndex: 1 });
          endMainDrag;
        },
      });
      let rightLastX = 0;
      let bottomLastY = 0;
      let topLastY = 0;
      let leftLastX = 0;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const rightDraggable = new Draggable($right, {
        trigger: `#${creativeid} .right, #${creativeid} .topRight, #${creativeid} .bottomRight`,
        cursor: "e-resize",
        onDrag: updateRight,
        onPress: function () {
          rightLastX = this.x;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const bottomDraggable = new Draggable($bottom, {
        trigger: `#${creativeid} .bottom, #${creativeid} .bottomRight, #${creativeid} .bottomLeft`,
        cursor: "s-resize",
        onDrag: updateBottom,
        onPress: function () {
          bottomLastY = this.y;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const topDraggable = new Draggable($top, {
        trigger: `#${creativeid} .top, #${creativeid} .topRight, #${creativeid} .topLeft`,
        cursor: "n-resize",
        onDrag: updateTop,
        onPress: function () {
          topLastY = this.y;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const leftDraggable = new Draggable($left, {
        trigger: `#${creativeid} .left, #${creativeid} .topLeft, #${creativeid} .bottomLeft`,
        cursor: "w-resize",
        onDrag: updateLeft,
        onPress: function () {
          leftLastX = this.x;
          mainDraggable.disable();
        },
        onDragEnd: updateCreativeSizeState,
        onRelease: function () {
          mainDraggable.enable();
        },
      });

      function endMainDrag() {
        const transformValue =
          componentScope.$refs[componentScope.layer.id].style.transform;
        const coordinates = transformValue.split("3d(")[1].split(",");
        const newX = Math.round(coordinates[0].split("px")[0]);
        const newY = Math.round(coordinates[1].split("px")[0]);
        componentScope.updateProperties({
          transform: `translate3d(${newX}, ${newY}, 0)`,
        });
      }
      function updateTop() {
        const diffY = Math.round(this.y - topLastY);
        gsap.set($container, { height: "-=" + diffY, y: "+=" + diffY });
        topLastY = this.y;
      }
      function updateRight() {
        const diffX = Math.round(this.x - rightLastX);
        gsap.set($container, { width: "+=" + diffX });
        rightLastX = this.x;
      }
      function updateBottom() {
        const diffY = Math.round(this.y - bottomLastY);
        gsap.set($container, { height: "+=" + diffY });
        bottomLastY = this.y;
      }
      function updateLeft() {
        const diffX = Math.round(this.x - leftLastX);
        gsap.set($container, { width: "-=" + diffX, x: "+=" + diffX });
        leftLastX = this.x;
      }

      function updateCreativeSizeState() {
        const layerHeight = gsap.getProperty($container, "height");
        const layerWidth = gsap.getProperty($container, "width");
        const transformValue =
          componentScope.$refs[componentScope.layer.id].style.transform;
        const coordinates = transformValue.split("3d(")[1].split(",");
        const newX = Math.round(coordinates[0].split("px")[0]);
        const newY = Math.round(coordinates[1].split("px")[0]);
        componentScope.updateProperties({
          transform: `translate3d(${newX}, ${newY}, 0)`,
          x: newX,
          y: newY,
          top: "2%",
          left: "2%",
        });
        componentScope.updatePropertiesToAllKF({
          height: layerHeight,
          width: layerWidth,
          top: "2%",
          left: "2%",
        });
      }
    },
  },
  mounted() {
    if (!this.isResizeTool) {
      gsap.registerPlugin(Draggable);
      this.dragCanvas();
    }
  },
});
