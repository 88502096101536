



















import Vue from "vue";
import { userMixin } from "@/mixins/userMixin";
import { bannerMixin } from "@/mixins/bannerMixin";
import OtomoTable from "../Table/OtomoTable.vue";
import { TableParamsProps } from "@/typings/Editors";

export default Vue.extend({
  name: "ClientSelector",
  mixins: [userMixin, bannerMixin],
  components: { OtomoTable },
  data: () => ({
    isTableLoading: true,
    currentTableParams: {
      hashId: null,
      activePage: 1,
      count: 0,
      pagesCount: 1,
      size: 200,
    },
    otomoTableHeaders: [
      { text: "CLIENT NAME", align: "center", value: "name", sortable: false },
      { text: "ACTIONS", align: "center", value: "actions", sortable: false },
      {
        text: "CAMPAIGNS",
        align: "center",
        value: "campaigns",
        sortable: false,
      },
    ],
    buttons: [],
  }),
  methods: {
    async getClientRequest() {
      await this.getClients(this.currentTableParams).then(() => {
        this.isTableLoading = false;
      });
    },
    refreshTablePage: function (params: TableParamsProps) {
      this.isTableLoading = true;
      this.currentTableParams = {
        activePage: params.page,
        size: params.itemsPerPage === -1 ? 200 : params.itemsPerPage,
      };
      this.getClientRequest();
    },
    setClientHandler: async function (client: { hashid: string }) {
      await this.setSelectedClient(client);
      await this.$router.push({
        name: "SelectCampaign",
        params: { clientid: client.hashid },
      });
    },
  },
  beforeMount: async function () {
    await this.setSelectedClient(undefined);
  },
  mounted() {
    this.isTableLoading = true;
    this.getClientRequest();
  },
});
