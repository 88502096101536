


















import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { LayerType } from "@/typings/Enums";

export default Vue.extend({
  name: "LayerIcon.vue",
  mixins: [bannerMixin],
  props: {
    layerType: {
      type: String,
      default: LayerType.Text,
    },
  },
});
