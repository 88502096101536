





































































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "BannersizeControl.vue",
  data: () => ({
    items: ["Foo", "Bar", "Fizz", "Buzz"],
  }),
});
