import { mapActions, mapGetters, mapState } from "vuex";
import { OtomoCanvasState } from "@/typings/Editors";

export const bannerMixin = {
  computed: {
    ...mapGetters(["getBannerProperties", "getMode"]),
    ...mapState<OtomoCanvasState>({
      width: (state: OtomoCanvasState) => state.banner.properties.width,
      minWidth: (state: OtomoCanvasState) => state.banner.properties.width,
      height: (state: OtomoCanvasState) => state.banner.properties.height,
      minHeight: (state: OtomoCanvasState) => state.banner.properties.height,
      backgroundColor: (state: OtomoCanvasState) =>
        state.banner.properties.backgroundColor,
      layers: (state: OtomoCanvasState) => state.banner.layers,
      banner: (state: OtomoCanvasState) => state.banner,
      state: (state: OtomoCanvasState) => state,
      selectedKeyframe: (state: OtomoCanvasState) => state.selectedKeyframe,
    }),
  },
  methods: {
    ...mapActions({
      addLayer: "addLayer",
      clearToOriginalState: "clearToOriginalState",
      clearEditorState: "clearEditorState",
      createFolder: "createFolder",
      createKeyframe: "createKeyframe",
      deleteCampaign: "deleteCampaign",
      deleteCreative: "deleteCreative",
      deleteKeyframe: "deleteKeyframe",
      deleteLayer: "deleteLayer",
      downloadCreative: "downloadCreative",
      duplicateLayer: "duplicateLayer",
      getAssetsLibrary: "getAssetsLibrary",
      getCampaign: "getCampaign",
      getCampaigns: "getCampaigns",
      getCreative: "getCreative",
      getCreatives: "getCreatives",
      resizeBanner: "resizeBanner",
      saveCampaign: "saveCampaign",
      saveCreative: "saveCreative",
      setBannerOtomoProperties: "setBannerOtomoProperties",
      setBannerStyleProperties: "setBannerStyleProperties",
      setCanvas: "setCanvasElement",
      setOtomoToastValues: "setOtomoToastValues",
      setSelectedKeyframe: "setSelectedKeyframe",
      setSelectedLayer: "setSelectedLayer",
      toggleAsset: "toggleAsset",
      toggleEditModeOff: "toggleEditModeOff",
      toggleEditModeOn: "toggleEditModeOn",
      toggleEditorMode: "toggleEditorMode",
      updateAnimatedCanvas: "updateAnimatedCanvas",
      updateAssetManager: "updateAssetManager",
      updateCreative: "updateCreative",
      updateCreativeName: "updateCreativeName",
      updateHoverState: "updateHoverState",
      updateKeyframe: "updateKeyframe",
      updateLayer: "updateLayer",
      updateLayerList: "updateLayerList",
      updateProperties: "updateProperties",
      updatePropertiesToAllKF: "updatePropertiesToAllKF",
      updateSelectedLayer: "updateSelectedLayer",
      uploadAsset: "uploadAsset",
      deleteAsset: "deleteAsset",
    }),
  },
};
