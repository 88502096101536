















































































































































































































































































































































import Vue from "vue";
import gsap from "gsap";
// TO-DO: add a new declaration (.d.ts) file containing `declare module 'gsap/Draggable';`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Draggable } from "gsap/Draggable.js";
import { bannerMixin } from "@/mixins/bannerMixin";
import { LayerType, NotificationTypes, RESIZE_OPTIONS } from "@/typings/Enums";
import {
  getInitialFolderValues,
  // getDefaultImageProps,
  getNewKeyframe,
} from "@/utils/layersAndPropsHandling";
import {
  AssetItem,
  Size,
  BannerProps,
  OtomoCanvasState,
} from "@/typings/Editors";
import SidebarInput from "./Controls/ControlsShapes/SidebarInput.vue";
import PlayerControls from "./Timeline/PlayerControls.vue";
import vSelect from "vue-select";
Vue.component("vue-select", vSelect);
// const getDefaultImageProps = () => ({
//   ...getNewKeyframe(0, {
//     backgroundRepeat: "no-repeat",
//     backgroundSize: "contain",
//     backgroundPosition: "center center",
//     display: "inline-block",
//     height: "100%",
//     opacity: 1,
//     position: "absolute",
//     width: "100%",
//     x: 0,
//     y: 0,
//     zIndex: 1,
//   }),
// });

const MAX_SCALE_VALUE = 1;
const CANVAS_ID = "#gsap_canvas";

export default Vue.extend({
  name: "Toolbar.vue",
  mixins: [bannerMixin],
  components: { SidebarInput, PlayerControls },
  data: () => ({
    sizes: [100, 75, 50],
    resizeOptions: RESIZE_OPTIONS,
    scaleValue: 100,
    selectedResize: null,
    inputSizeWidth: null,
    inputSizeHeight: null,
    isCanvasDraggable: false,
    isResizeButtonVisible: false,
    toggleDefault: [],
    originalBanner: null,
    zoomValues: [300, 275, 250, 225, 200, 175, 150, 125, 100, 75, 50, 25, 12.5],
    buttonText: "View Stage",
  }),
  props: {
    isResizeTool: {
      type: Boolean,
      default: false,
    },
    theTimeline: {
      type: gsap.core.Timeline,
      default: null,
    },
  },
  methods: {
    //(old code )
    // viewOffCanvasLayers: function () {
    //   this.state.isEditMode === true
    //     ? this.toggleEditModeOff()
    //     : this.toggleEditModeOn();
    // },
    viewOffCanvasLayers: function () {
      this.state.isEditMode === true
        ? this.toggleEditModeOff()
        : this.toggleEditModeOn();
      this.toggleButtonText();
    },
    toggleButtonText() {
      this.buttonText = this.state.isEditMode ? "View Canvas" : "View Stage";
    },
    changeCreativeWidth: function (value: number) {
      this.selectedResize = { height: this.selectedResize.height };
      this.inputSizeWidth = value < 1 ? 1 : value;
      this.selectedResize.width = value < 1 ? 1 : value;
      this.executeResize();
    },
    changeCreativeHeight: function (value: number) {
      this.selectedResize = { width: this.selectedResize.width };
      this.inputSizeHeight = value < 1 ? 1 : value;
      this.selectedResize.height = value < 1 ? 1 : value;
      this.executeResize();
    },
    createFolderLayer: function () {
      const layerName = !this.state.banner.layersCreationCount
        ? `folder-${this.state.banner.layers.length + 1}`
        : `folder-${this.state.banner.layersCreationCount}`;
      this.createFolder(getInitialFolderValues(layerName));
    },
    createText: function () {
      const layerName = !this.state.banner.layersCreationCount
        ? `text-${this.state.banner.layers.length + 1}`
        : `text-${this.state.banner.layersCreationCount}`;
      this.addLayer({
        name: layerName,
        type: LayerType.Text,
        text: "Text Sample",
        value: "Text Sample",
        id: `txt-${new Date().getTime()}`,
        properties: [
          getNewKeyframe(0, {
            color: "#000000",
            display: "inline-block",
            fontFamily: "Arial",
            fontSize: "32px",
            fontWeight: "400",
            height: "auto",
            letterSpacing: "0em",
            lineHeight: "1",
            opacity: 1,
            position: "absolute",
            // transform: "translate3d(0px, 0px, 0px)",
            width: "auto",
            x: 20,
            y: 20,
            zIndex: 1,
          }),
        ],
      });
    },
    clickImageButton: function () {
      this.setSelectedLayer(undefined);
      this.updateAssetManager({
        mediaType: "image",
        isModalLibraryOpen: true,
      });
    },
    clickVideoButton: function () {
      this.setSelectedLayer(undefined);
      this.updateAssetManager({
        mediaType: "video",
        isModalLibraryOpen: true,
      });
    },
    createCTA: function () {
      const layerName = !this.state.banner.layersCreationCount
        ? `cta-${this.state.banner.layers.length + 1}`
        : `cta-${this.state.banner.layersCreationCount}`;

      this.addLayer({
        name: layerName,
        type: LayerType.Cta,
        text: "CTA Sample",
        value: "CTA Sample",
        id: `cta-${new Date().getTime()}`,
        // hoverProperties: {
        //   duration: 1,
        //   properties: {
        //     scale: 1,
        //   },
        // },
        properties: [
          getNewKeyframe(0, {
            paddingTop: "8px",
            paddingRight: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            color: "#fff",
            borderRadius: "0px",
            backgroundColor: "#000000",
            display: "inline-block",
            fontFamily: "Arial",
            fontSize: "20px",
            fontWeight: "400",
            height: "auto",
            letterSpacing: "0em",
            lineHeight: "1",
            zIndex: 1,
            opacity: 1,
            position: "absolute",
            borderStyle: "none",
            width: "auto",
            x: 20,
            y: 20,
          }),
        ],
      });
    },
    toggleMode: function () {
      this.toggleEditorMode(!this.getMode);
    },
    saveCreativeHandler: async function () {
      const campaignId = this.$route.params.campaignid;
      const fonts = this.state.banner.assets.filter(
        (asset: AssetItem) => asset.type === "font"
      );
      const assets = this.state.banner.assets.filter(
        (asset: AssetItem) => asset.type !== "font"
      );
      let creativeProps = {
        campaignHashId: campaignId,
        banner: {
          ...this.state.banner,
          layers: {
            elements: [...this.state.banner.layers],
            fonts,
          },
          assets,
        },
      };

      // Removes the hashid to store a new resized version
      if (this.isResizeTool) {
        delete creativeProps.banner.hashid;
      }

      // When is editor adds the size to the name
      const splitName = this.state.banner.name.split(" [");
      this.setBannerOtomoProperties({
        key: "name",
        value: `${splitName[0]} [${this.state.banner.properties.width}x${this.state.banner.properties.height}]`,
      });
      creativeProps.banner.name = this.state.banner.name;

      if (creativeProps.banner.hashid) {
        this.updateCreative(creativeProps);
        this.setOtomoToastValues({
          type: NotificationTypes.Success,
          message: `The "${creativeProps.banner.name}" creative was updated successfully.`,
          timeout: 4000,
        });
      } else {
        await this.saveCreative(creativeProps);
        this.setOtomoToastValues({
          type: NotificationTypes.Success,
          message: `The "${creativeProps.banner.name}" creative was saved successfully.`,
          timeout: 4001,
        });
        if (this.isResizeTool) {
          this.$router.push(
            `../../creatives/${this.state.banner.hashid}/resize-tool`
          );
        } else {
          this.$router.push(`./creatives/${this.state.banner.hashid}/editor`);
        }
      }

      this.clearBannerSelectedStates();
    },
    clearBannerSelectedStates: function () {
      this.setSelectedLayer(null);
      this.setSelectedKeyframe(null);
    },
    backToCreativesHandler: async function () {
      // this.$router.push(
      //   this.state.banner.hashid ? "../../creatives" : "creatives"
      // );
      this.toggleEditModeOff();
      // this.clearBannerSelectedStates();
      await this.$router.push({ name: "SelectCreative" });
    },
    zoomInCanvasHandler: function () {
      const zoomPosition = this.zoomValues.indexOf(this.scaleValue);
      if (zoomPosition > 0) {
        this.scaleValue = this.zoomValues[zoomPosition - 1];
        gsap.to(CANVAS_ID, {
          scale: `${MAX_SCALE_VALUE * (this.scaleValue * 0.01)}`,
        });
      }
    },
    zoomOutCanvasHandler: function () {
      const zoomPosition = this.zoomValues.indexOf(this.scaleValue);
      if (zoomPosition < this.zoomValues.length - 1) {
        this.scaleValue = this.zoomValues[zoomPosition + 1];
        gsap.to(CANVAS_ID, {
          scale: `${MAX_SCALE_VALUE * (this.scaleValue * 0.01)}`,
        });
      }
    },
    handleZoomChange: function (value: number) {
      gsap.to(CANVAS_ID, { scale: `${MAX_SCALE_VALUE * (value * 0.01)}` });
    },
    handleResize: function (value: Size) {
      if (!value.width) return;
      this.selectedResize = value;
      // this.inputSizeWidth = this.selectedResize.width;
      // this.inputSizeHeight = this.selectedResize.height;
      this.executeResize();
    },
    executeResize: function () {
      if (!this.originalBanner) this.originalBanner = this.state.banner;

      const splitName = this.state.banner.name.split(" [");
      this.resizeBanner({
        ...this.selectedResize,
        originalBanner: this.originalBanner,
      });
      this.setBannerStyleProperties({
        value: this.selectedResize.width,
        key: `width`,
      });
      this.setBannerStyleProperties({
        value: this.selectedResize.width,
        key: `minWidth`,
      });
      this.setBannerStyleProperties({
        value: this.selectedResize.height,
        key: `height`,
      });
      this.setBannerStyleProperties({
        value: this.selectedResize.height,
        key: `minHeight`,
      });
      this.setBannerOtomoProperties({
        key: "name",
        value: `${splitName[0]} [${this.selectedResize.width}x${this.selectedResize.width}]`,
      });
    },
    handleDownload: function () {
      const creativeid = this.$route.params.creativeid;
      if (creativeid) {
        this.downloadCreative(creativeid);
      } else {
        this.setOtomoToastValues({
          type: NotificationTypes.Warning,
          message: `Creative needs to be saved first.`,
          timeout: 4001,
        });
      }
    },
    dragCanvas: function () {
      this.isCanvasDraggable = !this.isCanvasDraggable;

      if (this.isCanvasDraggable) {
        Draggable.create("#gsap_canvas", {
          type: "x,y",
          edgeResistance: 0.65,
          bounds: "#gsap_canvas_wrapper",
          inertia: true,
        });
      } else {
        Draggable.get("#gsap_canvas").disable();
      }
    },
    resizeItem: function () {
      this.toggleEditModeOff();
      // this.clearBannerSelectedStates();
      this.$router.push(`resize-tool`);
    },

    exportFrame: async function (format: string) {
      const canvas = document.querySelector("#gsap_canvas");
      const frame_image = document.querySelector("#frame_image");
      if (!canvas || !frame_image) return;
      const xml = new XMLSerializer().serializeToString(canvas);
      const blobType = "image/" + format;
      const blob = new Blob([xml], { type: blobType });
      this.downloadBlob(blob, `file.${format}`);
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        return;
      };
      img.src = URL.createObjectURL(blob);
    },
    downloadBlob: function (blob: Blob, name: string) {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      document.body.removeChild(link);
    },
  },
  mounted() {
    gsap.registerPlugin(Draggable);

    const params = this.$route.params;
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!Object.getOwnPropertyDescriptor(params, "creativeid")) {
      // This would compile without any issue !
      this.isResizeButtonVisible = true;
    }
    this.$store.watch(
      (state: OtomoCanvasState) => state.banner,
      (value: BannerProps) => {
        this.selectedResize = {
          width: Number(value.properties.width),
          height: Number(value.properties.height),
        };
      }
    );
  },
  beforeMount() {
    this.clearBannerSelectedStates();
    this.selectedResize = {
      width: Number(this.state.banner.properties.width),
      height: Number(this.state.banner.properties.height),
    };
  },
});
