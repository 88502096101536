











































































































































































































































































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { OtomoCanvasState, KeyframeProp, AssetItem } from "@/typings/Editors";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
import ButtonInput from "./ControlsShapes/ButtonInput.vue";
// import SidebarLock from "./ControlsShapes/SidebarLock.vue";

export default Vue.extend({
  name: "TextControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
    ButtonInput,
    // SidebarLock,
  },
  mounted() {
    this.$store.watch(
      (state: OtomoCanvasState) => state.selectedKeyframe,
      (value: KeyframeProp) => (this.currentKeyframeProps = value?.properties)
    );
  },
  data: () => ({
    fonts: [
      "Arial",
      "Arial Black",
      "Courier New",
      "Georgia",
      "Times New Roman",
      "Tahoma",
      "Verdana",
    ],
    fontWeight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
    display: ["block", "flex", "inline", "inline-block", "table"],
    rollover: ["Scale Bounce", "op2", "op3", "op4"],
  }),
  computed: {
    getFonts() {
      return [
        ...this.fonts,
        ...this.state.banner.assets
          .filter((i: AssetItem) => i.type === "font")
          .map((i: AssetItem) => i.name.split(".")[0]),
      ];
    },
  },
  methods: {
    updateLayerPositioning: function (x: string, y: string) {
      this.updateProperties({
        transform: `translate(${x}px, ${y}px) rotate(${this.state.selectedKeyframe.properties.rotateZ}deg)`,
        x: `${x}`,
        y: `${y}`,
      });
    },
    changeTextContent: function (value: string) {
      this.updateSelectedLayer({
        ...this.state.selectedLayer,
        value,
        text: value,
      });
    },
  },
});
