




import Vue from "vue";

export default Vue.extend({
  name: "LoadingBar.vue",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
