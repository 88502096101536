





























import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";

export default Vue.extend({
  name: "LayerInfoControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
  },
  data: () => ({
    getNewOpacityWrapperId: `opacity-wrapper-${new Date().getTime()}`,
    opacityId: "",
  }),
  methods: {
    changeLayerName: function (name: string) {
      this.updateSelectedLayer({ ...this.state.selectedLayer, name });
    },
  },
});
