


































































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
import { OtomoCanvasState } from "@/typings/Editors";

export default Vue.extend({
  name: "CreativeBackgroundControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
  },
  data: () => ({
    dialog: false,
    bgSliderScale: 100,
    bgSliderPosX: 0,
    bgSliderPosY: 0,
  }),
  methods: {
    openAssetManagerHandler: function () {
      this.updateAssetManager({
        mediaType: "media",
        isModalLibraryOpen: true,
        isBackgroundImage: true,
      });
    },
    removeBackgroundImage: function () {
      this.setBannerStyleProperties({
        value: "url()",
        key: `backgroundImage`,
      });
      this.setBannerStyleProperties({
        value: "center",
        key: `backgroundPositionX`,
      }).then(() => (this.bgSliderPosX = 0));
      this.setBannerStyleProperties({
        value: "center",
        key: `backgroundPositionY`,
      }).then(() => (this.bgSliderPosY = 0));
    },
    updateBGPosX: function (posX: number) {
      this.setBannerStyleProperties({
        value: `${posX}%`,
        key: `backgroundPositionX`,
      });
    },
    updateBGPosY: function (posY: number) {
      this.setBannerStyleProperties({
        value: `${posY}%`,
        key: `backgroundPositionY`,
      });
    },
  },
  mounted() {
    this.$store.watch(
      (state: OtomoCanvasState) => state.banner.properties.backgroundPositionX,
      (value: string) => (this.bgSliderPosX = Number(value.slice(0, -1)))
    );
    this.$store.watch(
      (state: OtomoCanvasState) => state.banner.properties.backgroundPositionY,
      (value: string) => (this.bgSliderPosY = Number(value.slice(0, -1)))
    );
  },
});
