import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import SelectClient from "@/views/SelectClient.vue";
import SelectCampaign from "@/views/SelectCampaign.vue";
import SelectCreative from "@/views/SelectCreative.vue";
import NewCampaign from "../views/NewCampaign.vue";
import Editor from "../views/Editor.vue";
import ResizeTool from "../views/ResizeTool.vue";
import CreativeResize from "@/views/CreativeResize.vue";

Vue.use(VueRouter);

export const OTOMO_ROUTES: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/clients",
    name: "SelectClient",
    component: SelectClient,
    meta: {
      breadCrumb: {
        text: "Clients",
        to: { name: "SelectClient" },
      },
    },
  },
  {
    path: "/clients/:clientid",
    name: "SelectedCampaign",
    component: SelectCampaign,
    meta: {
      breadCrumb: {
        text: "Campaigns",
        to: { name: "SelectCampaign" },
      },
    },
  },
  {
    path: "/clients/:clientid/campaigns",
    name: "SelectCampaign",
    component: SelectCampaign,
    meta: {
      breadCrumb: {
        text: "Campaigns",
        to: { name: "SelectCampaign" },
      },
    },
  },
  {
    path: "/clients/:clientid/campaigns/:campaignid",
    name: "SelectedCreative",
    component: SelectCreative,
    meta: {
      breadCrumb: {
        text: "Creatives",
        to: { name: "SelectCreative" },
      },
    },
  },
  {
    path: "/clients/:clientid/campaigns/:campaignid/creatives",
    name: "SelectCreative",
    component: SelectCreative,
    meta: {
      breadCrumb: {
        text: "Creatives",
        to: { name: "SelectCreative" },
      },
    },
  },
  {
    path: "/clients/:clientid/campaigns/:campaignid/creatives/:creativeid/editor",
    name: "CreativeUpdate",
    component: Editor,
  },
  {
    path: "/new-campaign",
    name: "NewCampaign",
    component: NewCampaign,
  },
  {
    path: "/clients/:clientid/campaigns/:campaignid/editor",
    name: "Editor",
    component: Editor,
  },
  {
    path: "/clients/:clientid/campaigns/:campaignid/creatives/:creativeid/resize-tool",
    name: "ResizeTool",
    component: ResizeTool,
  },
  {
    path: "/clients/:clientid/campaigns/:campaignid/creatives/:creativeid/resize",
    name: "CreativeResize",
    component: CreativeResize,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: OTOMO_ROUTES,
});

export default router;
