


















































































































































































































































import Vue from "vue";
import { BannerProps } from "@/typings/Editors";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "OtomoTableInner",
  mixins: [bannerMixin],
  props: {
    tableItems: {
      type: Array,
    },
    tableItemClick: {
      type: Function,
      default: () => null,
    },
    tableType: {
      type: String,
    },
    creativePreviewClick: {
      type: Function,
      default: () => null,
    },
    creativeDeleteClick: {
      type: Function,
      default: () => null,
    },
    removeHandler: {
      type: Function,
      default: () => null,
    },
    closeDialogRemove: {
      type: Function,
      default: () => null,
    },
    openDialogRemove: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    handleDownload: function (hashid: unknown) {
      this.downloadCreative(hashid);
    },
    resizeItem(item: BannerProps) {
      this.$router.push(`creatives/${item.hashid}/resize-tool`);
    },
  },
});
