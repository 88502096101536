























import Vue from "vue";
import { userMixin } from "@/mixins/userMixin";
import { bannerMixin } from "@/mixins/bannerMixin";
import OtomoTable from "../Table/OtomoTable.vue";
import { TableParamsProps } from "@/typings/Editors";

export default Vue.extend({
  name: "ClientSelector",
  mixins: [userMixin, bannerMixin],
  components: { OtomoTable },
  data: () => ({
    isTableLoading: true,
    isDialogRemoveOpen: false,
    itemToBeRemoved: null,
    currentTableParams: {
      hashId: null,
      activePage: 1,
      count: 0,
      pagesCount: 1,
      size: 200,
    },
    otomoTableHeaders: [
      // {
      //   text: "THUMBNAIL",
      //   align: "center",
      //   value: "thumbnail",
      //   sortable: false,
      // },
      {
        text: "TEMPLATE NAME",
        align: "center",
        value: "name",
        sortable: false,
      },
      { text: "ACTIONS", align: "center", value: "actions", sortable: false },
      {
        text: "DATE EDITED",
        align: "center",
        value: "dateEdited",
        sortable: false,
      },
      {
        text: "DATE CREATED",
        align: "center",
        value: "dateCreated",
        sortable: false,
      },
    ],
    buttons: [],
  }),
  methods: {
    async getCreativeRequest() {
      const clientID = { hashId: "" };
      clientID.hashId = this.$route.params.clientid;

      await this.getClient(clientID);

      await this.getCampaign(this.$route.params.campaignid);

      this.currentTableParams.hashId = this.$route.params.campaignid;
      await this.getCreatives(this.currentTableParams).then(
        () => (this.isTableLoading = false)
      );
    },
    refreshTablePage: function (params: TableParamsProps) {
      this.isTableLoading = true;

      this.currentTableParams = {
        activePage: params.page,
        size: params.itemsPerPage === -1 ? 200 : params.itemsPerPage,
      };
      this.getCreativeRequest();
    },
    selectCampaignDetailHandler: async function (creativeId: string) {
      await this.$router.push({
        name: "CreativeUpdate",
        params: {
          creativeid: creativeId,
        },
      });
    },
    removeCreativeHandler: function () {
      this.currentTableParams = {
        activePage: this.state.creatives.activePage,
        count: this.state.creatives.count,
      };
      this.deleteCreative(this.itemToBeRemoved.hashid);
      this.refreshTablePage(this.currentTableParams);
      this.closeDialogRemoveCreative();
    },
    openDialogRemoveCreative(item: { [key: string]: unknown }) {
      this.itemToBeRemoved = item;
      this.isDialogRemoveOpen = true;
    },
    closeDialogRemoveCreative() {
      this.isDialogRemoveOpen = false;
    },
  },
  beforeMount: async function () {
    this.buttons = [
      {
        buttonIcon: "mdi-plus",
        text: "NEW TEMPLATE",
        to: "editor",
      },
    ];
    await this.getCreativeRequest();
  },
});
