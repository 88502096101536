















































































































































































/* eslint-disable */
// eslint-disable
import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { ColorProps } from "@/typings/ColorControl";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
// import SidebarLock from "./ControlsShapes/SidebarLock.vue";

export default Vue.extend({
  name: "CreativeControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
    // SidebarLock,
  },
  data: () => ({
    colorIsActive: false,
    colorOutlineIsActive: false,
    colorPickerColor: "#FFC107",
    colorOutlinePickerColor: "#FFC107",
    outlineSize: "",
    adServers: ["standard", "google-studio"],
    borderStyleItems: ["none", "solid"],
    type: "rgba",
    hex: "#FFC107",
    rgba: { r: 255, g: 193, b: 7, a: 1 },
    rgbaColor: "",
    alphaValue: 1,
  }),
  computed: {
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color[key] = Number(this.color[key].toFixed(2));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.rgbaColor = `rgba(${color.r !== undefined ? color.r : "0"}, ${
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color.g !== undefined ? color.g : "0"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          }, ${color.b !== undefined ? color.b : "0"}, ${
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color.a !== undefined ? color.a : "1"
          }`;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.alphaValue = color.a !== undefined ? color.a : "1";
          return color;
        }, {}),
        null,
        2
      );
    },
  },
  methods: {
    showBGPicker: function () {
      this.colorIsActive = !this.colorIsActive;
    },
    showOutlineColorPicker: function () {
      if (this.banner.properties.borderColor) {
        this.colorOutlinePickerColor = this.banner.properties.borderColor
      }
      this.colorOutlineIsActive = !this.colorOutlineIsActive;
    },
    updateBGColor: function (value: string) {
      this.setBannerStyleProperties({ value, key: `backgroundColor` });
      this.colorPickerColor = this.banner.properties.backgroundColor;
    },
    updateOutlineSize: function () {
      this.outlineSize = "1px;";
      const value: string = this.outlineSize;
      this.setBannerStyleProperties({ value, key: `borderWidth` });
    },
    updateOutlineStyle: function (value = 'solid') {
      this.updateOutlineSize()
      this.setBannerStyleProperties({ value, key: `borderStyle` });
      this.updateOutlineColor('#000000')
    },
    updateOutlineColor: function (value: string) {
      if (!this.state.banner.properties.borderStyle) {
        this.updateOutlineStyle();
      }
      if (!this.state.banner.properties.borderWidth) {
        this.updateOutlineSize();
      }

      this.setBannerStyleProperties({ value, key: `borderColor` });
      this.colorPickerColor = this.banner.properties.borderColor;
      this.colorOutlinePickerColor = this.banner.properties.borderColor
    },
    handleColorPickerOutlineInput: function () {
      this.colorOutlineIsActive = !this.colorOutlineIsActive;
    },
    changeOutlineWidth: function (borderWidth: unknown) {
      this.updateSelectedLayer({
        ...this.state.banner,
        borderWidth: this.outlineSize,
        properties: {
          ...this.state.banner.properties,
          borderWidth: borderWidth,
        },
      });
    },
    changeOutlineColor: function (color: ColorProps) {
      this.pickerColor = color;
      this.updateSelectedLayer({
        ...this.state.banner,
        borderColor: color,
        properties: {
          ...this.state.banner.properties,
          borderWidth: this.outlineSize,
          borderStyle: "solid",
          borderColor: color.hex,
        },
      });
    },
    changeCreativeWidth: function (value: string) {
      this.setBannerStyleProperties({ value, key: `width` });
      this.setBannerStyleProperties({ value, key: `minWidth` });
    },
    changeCreativeHeight: function (value: string) {
      this.setBannerStyleProperties({ value, key: `height` });
      this.setBannerStyleProperties({ value, key: `minHeight` });
    },
  },
  mounted() {
    this.colorPickerColor = this.state.banner.properties.backgroundColor;
  },
});
