








import Vue from "vue";
import NewCampaignForm from "@/components/Forms/NewCampaignForm.vue";

export default Vue.extend({
  name: "NewCampaign",
  components: {
    NewCampaignForm,
  },
});
