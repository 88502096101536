



























































































import Vue from "vue";
import gsap from "gsap";
import { CANVAS_ID } from "@/typings/Enums";
import { bannerMixin } from "@/mixins/bannerMixin";
import {
  AssetItem,
  BannerProps,
  CSSProperties,
  OtomoCanvasState,
} from "@/typings/Editors";
import TemporalLayerVue from "./EditorShapes/TemporalLayer.vue";
import TextLayerVue from "./EditorShapes/TextLayer.vue";
import ImageLayerVue from "./EditorShapes/ImageLayer.vue";
import CtaLayerVue from "./EditorShapes/CtaLayer.vue";
import VideoLayerVue from "./EditorShapes/VideoLayer.vue";
import FolderLayerVue from "./EditorShapes/FolderLayer.vue";
import LoadingBar from "./LoadingBar.vue";

export default Vue.extend({
  name: "OtomoCanvas.vue",
  mixins: [bannerMixin],
  props: {
    isResizeTool: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TemporalLayerVue,
    TextLayerVue,
    ImageLayerVue,
    CtaLayerVue,
    VideoLayerVue,
    FolderLayerVue,
    LoadingBar,
  },
  mounted() {
    this.$store.watch(
      (state: OtomoCanvasState) => state.banner,
      ({ properties }: BannerProps) => {
        gsap.set(CANVAS_ID, { ...properties, delay: 1 });
      }
    );

    this.$store.watch(
      (state: OtomoCanvasState) => state.banner.properties,
      (value: CSSProperties) => {
        gsap.set(CANVAS_ID, { ...value, delay: 1 });
      }
    );

    this.$store.watch(
      (state: OtomoCanvasState) => state.banner.assets,
      () => {
        this.loadCSSRules();
      }
    );
  },
  methods: {
    loadCSSRules: function () {
      const sheet = document.styleSheets[document.styleSheets.length - 1];

      this.state.banner.assets.map((a: AssetItem) => {
        sheet.insertRule(
          `@font-face { font-family:"${a.name.split(".")[0]}"; src:url("${
            a.url
          }") format("${a.name.split(".")[1]}"); }`,
          sheet.cssRules.length
        );
      });
    },
  },
});
