







































































































































import Vue from "vue";
import AdfontsControl from "./Controls/AdfontsControl.vue";
import AdvanceControl from "./Controls/AdvanceControl.vue";
// import AlignmentControl from "@/components/Editor/Controls/AlignmentControl.vue";
import ColorControl from "@/components/Editor/Controls/ColorControl.vue";
// import HoverControl from "@/components/Editor/Controls/HoverControl.vue";
// import ContainerControl from "./Controls/ContainerControl.vue";
import CreativeControl from "@/components/Editor/Controls/CreativeControl.vue";
import CreativeBackgroundControl from "./Controls/CreativeBackgroundControl.vue";
// import DropshadowControl from "./Controls/DropshadowControl.vue";
// import FilesizeControl from "./Controls/FilesizeControl.vue";
// import CtaArrowControl from "./Controls/CtaArrowControl.vue";
import GrouppositionControl from "./Controls/GrouppositionControl.vue";
import ImageassetsControl from "./Controls/ImageassetsControl.vue";
import VideoAssetsControl from "./Controls/VideoAssetsControl.vue";
import ImagepropertiesControl from "./Controls/ImagepropertiesControl.vue";
import VideoPropertiesControl from "./Controls/VideoPropertiesControl.vue";
import MarginControl from "@/components/Editor/Controls/MarginControl.vue";
import PaddingControl from "@/components/Editor/Controls/PaddingControl.vue";
import ShapeControl from "./Controls/ShapeControl.vue";
import TextControl from "@/components/Editor/Controls/TextControl.vue";
import TransformControl from "./Controls/TransformControl.vue";
import LayerInfoControl from "./Controls/LayerInfoControl.vue";
import KeyframeControl from "./Controls/KeyframeControl.vue";
// import BorderRadiusControl from "./Controls/BorderRadiusControl.vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { OtomoCanvasState } from "@/typings/Editors";

export default Vue.extend({
  name: "SideBar.vue",
  mixins: [bannerMixin],
  components: {
    // AlignmentControl,
    // BorderRadiusControl,
    // CtaArrowControl,
    // DropshadowControl,
    AdfontsControl,
    AdvanceControl,
    ColorControl,
    // ContainerControl,
    CreativeControl,
    CreativeBackgroundControl,
    // DropshadowControl,
    // FilesizeControl,
    GrouppositionControl,
    // HoverControl,
    ImageassetsControl,
    ImagepropertiesControl,
    VideoPropertiesControl,
    KeyframeControl,
    LayerInfoControl,
    MarginControl,
    PaddingControl,
    ShapeControl,
    TextControl,
    TransformControl,
    VideoAssetsControl,
  },
  data: () => ({
    fonts: ["Helvetica", "Comic Sans", "Times New Roman", "Arial"],
    sidebarPanels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  }),
  mounted() {
    this.$store.watch(
      (state: OtomoCanvasState) => state.selectedLayer,
      () => {
        this.sidebarPanels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      }
    );
  },
});
