



























import Vue from "vue";
import { userMixin } from "@/mixins/userMixin";
import { bannerMixin } from "@/mixins/bannerMixin";
import OtomoTable from "../Table/OtomoTable.vue";
import { TableParamsProps } from "@/typings/Editors";

export default Vue.extend({
  name: "CampaignSelector",
  mixins: [userMixin, bannerMixin],
  components: { OtomoTable },
  data: () => ({
    isContentLoading: true,
    isDialogAddCampaignOpen: false,
    isDialogRemoveOpen: false,
    itemToBeRemoved: null,
    currentTableParams: {
      hashId: null,
      activePage: 1,
      count: 0,
      pagesCount: 1,
      size: 200,
    },
    otomoTableHeaders: [
      // {
      //   text: "THUMBNAIL",
      //   align: "center",
      //   value: "thumbnail",
      //   sortable: false,
      // },
      {
        text: "CAMPAIGN NAME",
        align: "center",
        value: "name",
        sortable: false,
      },
      { text: "ACTIONS", align: "center", value: "actions", sortable: false },
      {
        text: "CREATIVES TEMPLATES",
        align: "center",
        value: "templates",
        sortable: false,
      },
      {
        text: "DATE EDITED",
        align: "center",
        value: "dateEdited",
        sortable: false,
      },
      {
        text: "DATE CREATED",
        align: "center",
        value: "dateCreated",
        sortable: false,
      },
    ],
    buttons: [],
  }),
  methods: {
    async getCampaignsRequest() {
      const clientID = { hashId: "" };
      clientID.hashId = this.$route.params.clientid;
      await this.getClient(clientID);

      this.currentTableParams.hashId = clientID.hashId;

      await this.getCampaigns(this.currentTableParams).then(
        () => (this.isContentLoading = false)
      );
    },
    refreshTablePage: function (params?: TableParamsProps) {
      this.isContentLoading = true;
      this.currentTableParams = {
        activePage: params?.page,
        size: params?.itemsPerPage === -1 ? 200 : params?.itemsPerPage,
      };
      this.getCampaignsRequest();
    },
    selectCampaignHandler: async function (campaignid: string) {
      await this.$router.push({
        name: "SelectCreative",
        params: { campaignid },
      });
    },
    addCampaignHandler: async function (newItemName: string) {
      const clientID = { hashId: "" };
      clientID.hashId = this.$route.params.clientid;
      this.currentTableParams = {
        activePage: this.state.campaigns.activePage,
        page: this.state.campaigns.activePage,
        itemsPerPage: 200,
      };

      await this.saveCampaign(newItemName);
      this.closeDialogAddCampaign();
      this.refreshTablePage(this.currentTableParams);
    },
    removeCampaignHandler: function () {
      this.currentTableParams = {
        activePage: this.state.campaigns.activePage,
      };
      this.deleteCampaign(this.itemToBeRemoved.hashid);
      this.refreshTablePage(this.currentTableParams);
      this.closeDialogRemoveCampaign();
    },
    openDialogAddCampaign() {
      this.isDialogAddCampaignOpen = true;
    },
    closeDialogAddCampaign() {
      this.isDialogAddCampaignOpen = false;
    },
    openDialogRemoveCampaign(item: { [key: string]: unknown }) {
      this.itemToBeRemoved = item;
      this.isDialogRemoveOpen = true;
    },
    closeDialogRemoveCampaign() {
      this.isDialogRemoveOpen = false;
    },
  },
  beforeMount: function () {
    this.buttons = [
      {
        openAction: this.openDialogAddCampaign,
        buttonIcon: "mdi-plus",
        // disabled: this.isContentLoading,
        text: "NEW CAMPAIGN",
      },
    ];
  },
});
