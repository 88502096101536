





























































































































































































































































import { bannerMixin } from "@/mixins/bannerMixin";
import Vue from "vue";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";

export default Vue.extend({
  name: "GrouppositionControl.vue",
  mixins: [bannerMixin],
  components: { SidebarInput },
  methods: {
    isPropActive: function (propName: string, val: string) {
      return this.state.selectedKeyframe.properties[propName] === val;
    },
    getPropActiveClass: function (propName: string, val: string) {
      return this.isPropActive(propName, val)
        ? "otomo__icons--toolbar--active"
        : "";
    },
    getAlignmentActiveClass: function (propName: string, val: string) {
      return this.getAlignmentActive(propName, val)
        ? "otomo__icons--toolbar--active"
        : "";
    },
    getAlignmentActive: function (propName: string, val: string) {
      const isRow =
        this.state.selectedKeyframe.properties.flexDirection === "row";
      if (isRow) {
        return this.getPropActiveClass(
          propName === "alignItems" ? "alignItems" : "justifyContent",
          val
        );
      } else {
        return this.getPropActiveClass(
          propName === "alignItems" ? "justifyContent" : "alignItems",
          val
        );
      }
    },
    updateAligmentProp: function (propName: string, val: string) {
      const isRow =
        this.state.selectedKeyframe.properties.flexDirection === "row";
      if (propName === "alignItems") {
        this.updateProperties({
          [isRow ? "alignItems" : "justifyContent"]: val,
        });
      } else {
        this.updateProperties({
          [isRow ? "justifyContent" : "alignItems"]: val,
        });
      }
    },
    updateLayerPositioning: function (x: string, y: string) {
      this.updateProperties({
        transform: `translate(${x}px, ${y}px) rotate(${this.state.selectedKeyframe.properties.rotateZ}deg)`,
        x: `${x}`,
        y: `${y}`,
      });
    },
  },
  data: () => ({
    spaceDistributionOptions: ["space around", "space between", "space evenly"],
  }),
});
