

















import Vue from "vue";

export default Vue.extend({
  name: "VideoPreview.vue",
  props: {
    video: {
      type: Object,
    },
    assetClick: {
      default: () => null,
    },
  },
  methods: {
    hoverIn: function () {
      this.$refs[this.video.id].play();
    },
    hoverOut: function () {
      this.$refs[this.video.id].pause();
    },
  },
});
