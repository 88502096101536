
































































































































import Vue from "vue";
import gsap from "gsap";
// TO-DO: add a new declaration (.d.ts) file containing `declare module 'gsap/Draggable';`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Draggable } from "gsap/Draggable.js";
import Layers from "@/components/Editor/Layers.vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import {
  ContextMenuEvent,
  KeyframeProp,
  Layer,
  OtomoCanvasState,
  BannerProps,
} from "@/typings/Editors";
import {
  rebuildCanvasAnimation,
  getKeframesList,
  getLayersList,
} from "@/utils/layersAndPropsHandling";
import { CANVAS_ID } from "@/typings/Enums";

const KF_WIDTH = 20;
const HALF_KF = KF_WIDTH / 2;
const INDICATOR_OFFSET = 2;
const MIN_TIMELINE_FRAMES = 12;
const ADDITIONAL_TIMELINE_FRAMES = 6;

export default Vue.extend({
  name: "Timeline.vue",
  mixins: [bannerMixin],
  components: { Layers },
  computed: {
    allLayersAndChildren() {
      return getLayersList([...this.state.banner.layers].reverse());
    },
    timelineFramesNumber() {
      let duration = this.realTimelineDuration;
      if (duration + ADDITIONAL_TIMELINE_FRAMES < MIN_TIMELINE_FRAMES) {
        duration = MIN_TIMELINE_FRAMES;
      } else {
        duration = duration + ADDITIONAL_TIMELINE_FRAMES;
      }
      return Math.ceil(duration);
    },
    realTimelineWidth() {
      return this.realTimelineDuration * this.frameWidth;
    },
    timelineWidth() {
      return this.timelineDuration * this.frameWidth;
    },
    realTimelineDuration() {
      return this.theTimeline.duration();
    },
    timelineDuration() {
      return Math.ceil(this.timelineFramesNumber);
    },
    frameWidth() {
      const tlWrapperRef = this.$refs.otomo__timeline_wrapper;
      const timelineWidth =
        tlWrapperRef.clientWidth - KF_WIDTH - INDICATOR_OFFSET;
      return timelineWidth / this.timelineDuration;
    },
  },
  data: () => ({
    scrubber: null,
    theTimeline: gsap.timeline({ defaults: { duration: 4 } }),
    zoom: 0,
    keyframeContextMenuOptions: [
      {
        name: "Delete keyframe",
        slug: "delete-keyframe",
        class: "delete-keyframe",
      },
    ],
    isVisible: true, // Add isVisible property
  }),
  methods: {
    handleVisibilityUpdate(isVisible: boolean) {
      // Update the isVisible property
      this.isVisible = isVisible;
      // console.log(isVisible);
    },
    zoomOut() {
      this.zoom = this.zoom - 10 || 0;
    },
    zoomIn() {
      this.zoom = this.zoom + 10 || 100;
    },
    playButtonClick() {
      this.theTimeline.play();
      this.setSelectedLayer(undefined);
      this.setSelectedKeyframe(undefined);
    },
    restartButtonClick() {
      this.theTimeline.restart();
      this.setSelectedLayer(undefined);
      this.setSelectedKeyframe(undefined);
    },
    openContextMenu(e: MouseEvent, item: KeyframeProp) {
      this.$refs.keyframeOptionsContextMenu.showMenu(e, item);
    },
    async keyframeContextMenuOptionClick(event: ContextMenuEvent) {
      this.setSelectedKeyframe(event.item);
      if (event.option.slug === "delete-keyframe") {
        await this.deleteKeyframe(event.item);
      }
    },
    setScrubberPosition() {
      const currentPosition = Number(
        this.$refs.otomo__timeline__scrubber.style.transform
          .split("3d(")[1]
          .split("px")[0]
      );

      const currentProgress =
        ((100 / this.realTimelineWidth) * currentPosition) / 100;

      this.theTimeline.progress(currentProgress);
    },
    createScrubber() {
      this.scrubber = new Draggable(this.$refs.otomo__timeline__scrubber, {
        type: "x",
        cursor: "col-resize",
        edgeResistance: 1,
        inertia: true,
        bounds: this.$refs.otomo__timeline__progress,
        onPress: () => this.theTimeline.pause(),
        onDrag: () => this.setScrubberPosition(),
      });
    },
    setSelectedLayerAndKeyframe(keyframe: KeyframeProp) {
      const layer = this.state.banner.layers.find(
        (layer: Layer) => layer.id === keyframe.layerId
      );
      if (!layer) return;
      this.setSelectedLayer(layer);
      this.setSelectedKeyframe(keyframe);
    },
    progressBarClick(e: PointerEvent) {
      this.theTimeline.pause();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.theTimeline.progress((1 / this.realTimelineWidth) * e.layerX);
    },
    renderTimeline(layers: Layer[]) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const actualProgress = this.theTimeline ? this.theTimeline.progress() : 1;
      this.theTimeline.kill();
      this.theTimeline = undefined;
      this.theTimeline = rebuildCanvasAnimation(
        [...layers].reverse(),
        actualProgress
      );
      this.theTimeline.pause();
      const timelineRef = this.theTimeline;
      const kfList = getKeframesList(layers);

      if (this.theTimeline.duration() !== this.state.banner.duration) {
        this.setBannerOtomoProperties({
          value: this.theTimeline.duration(),
          key: `duration`,
        });
      }

      timelineRef.eventCallback("onUpdate", () => {
        const distanceFromStart =
          this.realTimelineWidth * timelineRef.progress();
        gsap.set(this.$refs.otomo__timeline__scrubber, {
          transform: `translateX(${distanceFromStart}px)`,
        });
      });

      kfList.forEach((kf) => {
        let savedCurrentPosition: string;
        const keyFrameItem = new Draggable(`#keyframe-item-${kf.id}`, {
          type: "x",
          cursor: "grab",
          activeCursor: "grabbing",
          edgeResistance: 1,
          inertia: true,
          onPress: (e: PointerEvent) => {
            savedCurrentPosition = keyFrameItem.x;
            this.setSelectedLayerAndKeyframe(kf);
            this.theTimeline.progress(kf.duration + kf.startTime);
            this.progressBarClick(e);
          },
          onRelease: () => {
            const currentPosition = keyFrameItem.x;
            if (savedCurrentPosition === currentPosition) return;
            const exactTime =
              (this.timelineDuration / this.timelineWidth) * currentPosition;
            this.updateKeyframe({
              ...this.state.selectedKeyframe,
              startTime: exactTime < 0 ? 0 : Math.round(exactTime * 10) / 10,
            });
            this.renderTimeline([...this.state.banner.layers].reverse());
          },
        });

        let leftLastX = 0;
        function updateLeft() {
          var diffX = this.x - leftLastX;
          gsap.set(`#keyframe-item-${kf.id}`, {
            width: "-=" + diffX,
            x: "+=" + diffX,
          });
          gsap.set(`#keyframe-item-${kf.id} .otomo__timeline-handle-left`, {
            transform: "translate(0)",
          });
          leftLastX = this.x;
        }
        const leftHandle = new Draggable(
          `#keyframe-item-${kf.id} .otomo__timeline-handle-left`,
          {
            type: "x",
            cursor: "w-resize",
            onDrag: updateLeft,
            onPress: function () {
              self.setSelectedLayerAndKeyframe(kf);
              leftLastX = this.x;
              keyFrameItem.disable();
            },
            onRelease: function () {
              keyFrameItem.enable();
              const currentWidth = leftHandle.target.parentElement.clientWidth;
              const originalDuration = kf.duration;
              const originalStartTime = kf.startTime;
              const exactTime =
                (self.timelineDuration / self.timelineWidth) * currentWidth;
              const duration = Math.round(exactTime * 10) / 10;
              const newStartTime =
                originalStartTime - duration + originalDuration;
              const startTime =
                newStartTime < 0 ? 0 : Math.round(newStartTime * 10) / 10;

              self.updateKeyframe({
                ...self.state.selectedKeyframe,
                duration,
                startTime,
              });
              self.renderTimeline(self.state.banner.layers);
            },
          }
        );

        let rightLastX = 0;
        function updateRight() {
          var diffX = this.x - rightLastX;
          gsap.set(`#keyframe-item-${kf.id}`, { width: "+=" + diffX });
          gsap.set(`#keyframe-item-${kf.id} .otomo__timeline-handle-right`, {
            transform: "translate(0)",
          });
          rightLastX = this.x;
        }

        const rightHandle = new Draggable(
          `#keyframe-item-${kf.id} .otomo__timeline-handle-right`,
          {
            type: "x",
            cursor: "e-resize",
            onDrag: updateRight,
            onPress: function () {
              self.setSelectedLayerAndKeyframe(kf);
              rightLastX = this.x;
              keyFrameItem.disable();
            },
            onRelease: function () {
              keyFrameItem.enable();
              const currentWidth = rightHandle.target.parentElement.clientWidth;
              const exactTime =
                (self.timelineDuration / self.timelineWidth) * currentWidth;
              self.updateKeyframe({
                ...self.state.selectedKeyframe,
                duration: Math.round(exactTime * 10) / 10,
              });
            },
          }
        );

        const distanceFromStart =
          this.timelineDuration !== 0 ? this.frameWidth * kf.startTime : 0;

        let props: { [key: string]: string } = {
          transform: `translate(${distanceFromStart - HALF_KF}px)`,
        };

        if (kf.isAnimated) {
          leftHandle.enable();
          rightHandle.enable();
          props = {
            ...props,
            width: `${this.frameWidth * kf.duration + INDICATOR_OFFSET}px`,
            transform: `translate(${distanceFromStart}px)`,
          };
        } else {
          leftHandle.disable();
          props = { ...props, width: `${KF_WIDTH}px` };
          rightHandle.disable();
        }

        gsap.set(`#keyframe-item-${kf.id}`, props);
      });
    },
    layerItemClicked(kf: KeyframeProp) {
      if (!kf) return;
      const progressInSeconds = kf.duration + kf.startTime;
      this.theTimeline.progress(
        ((100 / this.realTimelineDuration) * progressInSeconds) / 100
      );
    },
    rebuildAtCurrentTime(
      layers: Layer[] = [...this.state.banner.layers].reverse()
    ) {
      gsap.set(CANVAS_ID, this.state.banner.properties);
      const actualProgress = this.theTimeline ? this.theTimeline.progress() : 0;
      this.theTimeline = rebuildCanvasAnimation(layers, actualProgress);
      this.renderTimeline(layers);
    },
  },
  mounted() {
    this.createScrubber();
    console.log("TIMELINE mounted");
    this.rebuildAtCurrentTime();

    this.$store.watch(
      (state: OtomoCanvasState) => state.selectedKeyframe,
      (kf: KeyframeProp) => this.layerItemClicked(kf)
    );

    this.$store.watch(
      (state: OtomoCanvasState) => state.banner.layers,
      (layers: Layer[] | undefined) => {
        if (layers) {
          console.log("TIMELINE state.banner.layers", layers);
          this.rebuildAtCurrentTime(layers);
        }
      }
    );

    this.$store.watch(
      (state: OtomoCanvasState) => state.banner,
      (banner: BannerProps | undefined) => {
        if (banner && banner.layers) {
          console.log("TIMELINE state.banner");
          this.rebuildAtCurrentTime(banner.layers);
        }
      }
    );
  },
});
