










import Vue from "vue";
import { userMixin } from "@/mixins/userMixin";
import CampaignSelector from "@/components/OtomoTable/CampaignSelector.vue";

export default Vue.extend({
  name: "SelectCampaign",
  components: {
    CampaignSelector,
  },
  mixins: [userMixin],
});
