










import Vue from "vue";
import { userMixin } from "@/mixins/userMixin";
import ClientSelector from "@/components/OtomoTable/ClientSelector.vue";

export default Vue.extend({
  name: "SelectClient",
  components: {
    ClientSelector,
  },
  mixins: [userMixin],
});
