






























import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "ImageassetsControl.vue",
  mixins: [bannerMixin],
  data: () => ({
    dialog: false,
  }),
  computed: {
    buttonText() {
      return this.state.selectedLayer.value
        ? "Change Image"
        : "Select an Image";
    },
  },
  methods: {
    openAssetManagerHandler: function () {
      this.updateAssetManager({
        mediaType: "image",
        isModalLibraryOpen: true,
      });
    },
  },
});
