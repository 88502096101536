

































import draggable from "vuedraggable";
import LayerItem from "@/components/Editor/LayerItem.vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { Layer } from "@/typings/Editors";
export default {
  name: "nested-layers",
  mixins: [bannerMixin],
  components: {
    draggable,
    LayerItem,
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  methods: {
    emitter(value: Layer[]): void {
      // console.log("list: ", value);
      // console.log("======== clicked layer: ", this.folderLayer);
      // this.$emit("input", { list: value, clickedLayer: this.folderLayer });
      // TODOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
      // change all value items to be position static
      // const list = value.map((item) => {
      //   return {
      //     ...item,
      //     properties: item.properties.map((p) => ({
      //       ...p.properties,
      //       position: "static",
      //     })),
      //   };
      // });
      // console.log("list::: ", value);
      this.$emit("input", value);
    },
  },
  props: {
    layerItemClicked: {
      type: Function,
      default: (): null => null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    folderLayer: {
      type: Object,
      default: null,
    },
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
};
