










import Vue from "vue";
import { userMixin } from "@/mixins/userMixin";
import CreativeSelector from "@/components/OtomoTable/CreativeSelector.vue";

export default Vue.extend({
  name: "SelectCreative",
  components: {
    CreativeSelector,
  },
  mixins: [userMixin],
});
