var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"otomo-layer d-flex justify-space-between align-center",class:( _obj = {}, _obj['otomo-layer--active'] = _vm.active === true, _obj ),attrs:{"flat":"","tile":"","color":_vm.color},on:{"click":function () {
      _vm.setSelectedLayer(_vm.layer);
      _vm.layerItemClicked(_vm.getLastKF(_vm.layer));
    }}},[_c('p',{staticClass:"ma-0"},[_c('layer-icon',{attrs:{"layerType":_vm.layer.type}}),_vm._v(" "+_vm._s(_vm.layer.name)+" ")],1),_c('div',[(_vm.isVisible !== false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.layer.isAllKeyframeUpdate ? '#1976d2' : '#0000008a'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleIsAllKeyframeUpdate(_vm.layer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-decision-outline")])],1)]}}],null,false,2341323257)},[_c('span',[_vm._v("Apply change to all keyframe")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.duplicateLayerItem(_vm.layer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1)]}}])},[_c('span',[_vm._v("Duplicate layer")])]),(_vm.isVisible !== false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.state.selectedKeyframe ||
            !_vm.state.selectedLayer ||
            _vm.state.selectedLayer.id !== _vm.layer.id},on:{"click":function($event){return _vm.animateSelectedKeyframe(!_vm.state.selectedKeyframe.isAnimated)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-circle-multiple-outline")])],1)]}}],null,false,4277172050)},[_c('span',[_vm._v("Animate selected frame")])]):_vm._e(),(_vm.isVisible !== false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.createKeyframe(_vm.layer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-multiple")])],1)]}}],null,false,2764055571)},[_c('span',[_vm._v("Add new frame to layer")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.deleteButtonHandler}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])},[_c('span',[_vm._v("Delete layer")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.layer.hidden),expression:"!layer.hidden"}],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisibility(_vm.layer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}])},[_c('span',[_vm._v("Hide layer")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!!_vm.layer.hidden),expression:"!!layer.hidden"}],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisibility(_vm.layer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-off-outline")])],1)]}}])},[_c('span',[_vm._v("Show layer")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }