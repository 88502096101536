





































import Vue from "vue";
import Toolbar from "@/components/Editor/Toolbar.vue";
import Layers from "@/components/Editor/Layers.vue";
import SideBar from "@/components/Editor/SideBar.vue";
import OtomoCanvas from "@/components/Editor/OtomoCanvas.vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import Timeline from "@/components/Editor/Timeline/Timeline.vue";
import AssetsManager from "@/components/Editor/Controls/ControlsShapes/AssetsManager.vue";

export default Vue.extend({
  name: "Editor",
  components: {
    Timeline,
    OtomoCanvas,
    Layers,
    SideBar,
    Toolbar,
    AssetsManager,
  },
  mixins: [bannerMixin],
  computed: {
    isAnimating() {
      return this.getMode;
    },
  },
  data: () => ({
    isLoading: true,
  }),
  methods: {
    async loadState() {
      this.clearEditorState();
      const client = { hashid: "" };
      const campaign = { hashid: "" };
      const creativeid = this.$route.params.creativeid;

      client.hashid = this.$route.params.clientid;
      campaign.hashid = this.$route.params.campaignid;

      this.state.banner.campaign = campaign;

      if (creativeid) {
        await this.getCreative(creativeid);
      }
      this.isLoading = false;
    },
    handleClick: function (event: Event) {
      const target = event.target as HTMLInputElement;

      if (target && target.id === "gsap_canvas_wrapper")
        this.setSelectedLayer(undefined);
    },
  },
  beforeMount: async function () {
    await this.loadState();
  },
});
