import Vue from "vue";
import gsap from "gsap";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "vue-select/dist/vue-select.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
// TO-DO: add a new declaration (.d.ts) file containing `declare module 'vue-simple-context-menu';`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueSimpleContextMenu from "vue-simple-context-menu";
Vue.config.productionTip = false;
export const bus = new Vue();

gsap.config({ force3D: false });

Vue.component("vue-simple-context-menu", VueSimpleContextMenu);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
