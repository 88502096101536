var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"#e9ecef"}},[_vm._v(" "+_vm._s(("" + (this.state.selectedLayer.type === "Text" ? "Text" : "CTA")))+" ")]),_c('v-expansion-panel-content',{attrs:{"color":"#f8fafb"}},[_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-0"},[_c('sidebar-input',{attrs:{"textarea":"","inputText":this.state.selectedLayer.type === "Text"
              ? "TEXT Content"
              : "CTA Content","cssClass":"sidebar-input-lg","inputChange":function (value) { return _vm.changeTextContent(value.replace(/\n\r?/g, '<br />')); },"valueProp":this.state.selectedLayer.text.replaceAll('<br />', '\n')}})],1)],1),_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-0"},[_c('sidebar-input',{attrs:{"combobox":"","inputText":"FONT","cssClass":"sidebar-input-lg","comboboxItems":_vm.getFonts,"inputChange":function (fontFamily) { return _vm.updatePropertiesToAllKF({ fontFamily: fontFamily }); },"valueProp":this.state.selectedKeyframe.properties.fontFamily}})],1)],1),_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-0"},[_c('sidebar-input',{attrs:{"inputText":"FONT SIZE","cssClass":"mr-7","inputChange":function (fontSize) { return _vm.updatePropertiesToAllKF({ fontSize: fontSize }); },"valueProp":this.state.selectedKeyframe.properties.fontSize}}),_c('sidebar-input',{attrs:{"inputText":"LINE HEIGHT","inputChange":function (lineHeight) { return _vm.updatePropertiesToAllKF({ lineHeight: lineHeight }); },"valueProp":this.state.selectedKeyframe.properties.lineHeight}})],1)],1),_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-0 mr-2"},[_c('sidebar-input',{attrs:{"cssClass":"mr-7","inputText":"WIDTH","inputChange":function (width) { return _vm.updatePropertiesToAllKF({ width: width }); },"valueProp":("" + (this.state.selectedKeyframe.properties.width))}}),_c('sidebar-input',{attrs:{"inputText":"HEIGHT","inputChange":function (height) { return _vm.updatePropertiesToAllKF({ height: height }); },"valueProp":("" + (this.state.selectedKeyframe.properties.height))}})],1)],1),_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-0"},[_c('sidebar-input',{attrs:{"inputText":"X POSITION","cssClass":"mr-7","inputChange":function (x) { return _vm.updateLayerPositioning(
                x,
                this$1.state.selectedKeyframe.properties.y
              ); },"valueProp":("" + (this.state.selectedKeyframe.properties.x || 0))}}),_c('sidebar-input',{attrs:{"inputText":"Y POSITION","inputChange":function (y) { return _vm.updateLayerPositioning(
                this$1.state.selectedKeyframe.properties.x,
                y
              ); },"valueProp":("" + (this.state.selectedKeyframe.properties.y || 0))}})],1)],1),_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-0"},[(this.state.selectedLayer.type === 'Cta')?[_c('sidebar-input',{attrs:{"inputText":"CORNER RADIUS","cssClass":"mr-7","inputChange":function (borderRadius) { return _vm.updatePropertiesToAllKF({ borderRadius: borderRadius }); },"valueProp":this.state.selectedKeyframe.properties.borderRadius}})]:_vm._e(),_c('sidebar-input',{attrs:{"inputText":"LETTER SPACING","inputChange":function (letterSpacing) { return _vm.updatePropertiesToAllKF({ letterSpacing: letterSpacing }); },"valueProp":this.state.selectedKeyframe.properties.letterSpacing}})],2)],1),_c('v-row',{staticClass:"align-end mt-2"},[_c('v-col',{staticClass:"pt-1"},[_c('button-input',{attrs:{"sidebarButtonIcon":"mdi-format-align-left","handleClickIcon":function () { return _vm.updatePropertiesToAllKF({ textAlign: "left" }); },"buttonVal":"left" === this.state.selectedKeyframe.properties.textAlign}}),_c('button-input',{attrs:{"sidebarButtonIcon":"mdi-format-align-center","handleClickIcon":function () { return _vm.updatePropertiesToAllKF({ textAlign: "center" }); },"buttonVal":"center" === this.state.selectedKeyframe.properties.textAlign}}),_c('button-input',{attrs:{"sidebarButtonIcon":"mdi-format-align-right","handleClickIcon":function () { return _vm.updatePropertiesToAllKF({ textAlign: "right" }); },"buttonVal":"right" === this.state.selectedKeyframe.properties.textAlign}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }