import {
  BannerProps,
  TableParams,
  Creative,
  AssetRequest,
} from "@/typings/Editors";
import messages from "../constants/messages.json";
interface RequestError {
  status: string;
  body: {
    reason: string;
  };
}

type APIResponses = Creative;

type APICallType<APIResponses> = (
  endpoint: string,
  method: string,
  body: unknown,
  authenticated: boolean,
  isFile?: boolean,
  errorHandling?: (message: string) => void
) => Promise<APIResponses>;

const getHeaders = (isFile = false) => {
  const token = localStorage.getItem("otomo_tk") || null;
  return {
    Accept: isFile ? "*/*" : "application/json",
    authorization: `Bearer ${token}`,
  };
};
const errorText = (error: RequestError) =>
  `${error.status} - ${error.body.reason}`;

const callAPI: APICallType<APIResponses> = async (
  endpoint,
  method,
  body,
  authenticated,
  isFile = false,
  errorHandling
) => {
  try {
    const headers: HeadersInit = getHeaders(isFile);
    if (!isFile) headers["Content-Type"] = "application/json";

    const config = {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    };

    if (isFile) config.body = body as string;

    const token = localStorage.getItem("otomo_tk") || null;
    if (authenticated && !token) {
      return Promise.reject({ message: "No token saved" });
    }

    const response = await fetch(endpoint, config);

    if (response.status === 204 || response.status === 403) {
      return response.text().then((payload) => ({ payload, response }));
    } else if (response.status === 400) {
      response.json().then((payload) => {
        if (errorHandling) errorHandling(payload.message);
      });
      // To-Do: check return, if we add an existing asset we are getting
      // a response, but if we try to add the same asset 1 more time is not
      // triggering and error
      return response.json().then((payload) => {
        return { payload: { message: payload.message }, response: response };
      });
      // return;
    } else if (response.status === 413) {
      return {
        payload: { message: messages.tooLarge.message },
        response: response,
      };
    } else if (response.status === 401) {
      response.json().then((payload) => {
        if (errorHandling) errorHandling(payload.message);
      });
      throw response;
      // return {
      //   payload: messages.Unauthenticated,
      //   response: response,
      // };
    } else {
      const payload = await response.json();

      if (!response.ok) {
        try {
          const errorPayload = JSON.parse(payload);
          return Promise.reject(errorPayload);
        } catch (err) {
          return Promise.reject(payload);
        }
      }
      return payload;
    }
  } catch (e) {
    if (typeof e === "string") {
      const error = JSON.parse(e);
      throw error;
    }
    throw e;
  }
};

export default {
  login({
    username,
    password,
    errorHandling,
  }: {
    username: string;
    password: string;
    errorHandling: () => void;
  }): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}/auth/login`,
        "POST",
        { username, password },
        false,
        false,
        errorHandling
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getProfile(): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}auth/profile`,
        "GET",
        null,
        false
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getClients({ activePage, size }: TableParams): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}/api/client/all/?page=${activePage}&size=${size}&sortBy=name&sortType=asc`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getClient({ hashId }: { hashId: string }): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}/api/client/${hashId}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getCampaigns({ hashId, activePage }: TableParams): unknown {
    const page = activePage === undefined ? 1 : activePage;
    try {
      return callAPI(
        `${
          process.env.VUE_APP_API_BASE_URL
        }api/campaign/all/${hashId}/?page=${page}&size=${200}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getCampaign(hashId: string): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/campaign/${hashId}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  saveCampaign(clientHashid: string, campaignName: string): unknown {
    const campaign = {
      clientHashid: clientHashid,
      name: campaignName,
    };
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/campaign`,
        "POST",
        campaign,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  deleteCampaign(campaignHashId: string): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/campaign/${campaignHashId}`,
        "DELETE",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getCreatives({ hashId, activePage }: TableParams): unknown {
    const page = activePage === undefined ? 1 : activePage;
    try {
      return callAPI(
        `${
          process.env.VUE_APP_API_BASE_URL
        }api/builder/all/${hashId}?page=${page}&size=${200}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getCreative(hashid: string): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/${hashid}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  saveCreative({
    campaignHashId,
    banner: payload,
  }: {
    campaignHashId: string;
    banner: BannerProps;
  }): unknown {
    const creative = {
      campaignHashId,
      name: payload.name,
      payload,
    };
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/builder`,
        "POST",
        creative,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  updateCreative({
    campaignHashId,
    banner: payload,
  }: {
    campaignHashId: string;
    banner: BannerProps;
  }): unknown {
    const creative = {
      campaignHashId,
      name: payload.name,
      hashid: payload.hashid,
      payload,
    };
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/`,
        "PUT",
        creative,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  deleteCreative(creativeHashId: string): Promise<Creative> {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/${creativeHashId}`,
        "DELETE",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  getAssetsLibrary(hashid: string): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/media/library/${hashid}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
  uploadAsset(request: AssetRequest): Promise<unknown> {
    const { hashid: campaignHashId, asset } = request;
    const formData = new FormData();
    formData.append("asset", asset, asset.name);
    try {
      return fetch(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/media/upload/${campaignHashId}`,
        {
          method: "POST",
          headers: getHeaders(true),
          body: formData,
        }
      );
    } catch (error) {
      throw errorText(error as RequestError);
    }
  },
  deleteAsset(request: any): Promise<unknown> {
    const { hashid: campaignHashId, assetId } = request;
    try {
      return fetch(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/media/library/${campaignHashId}/${assetId}`,
        {
          method: "DELETE",
          headers: getHeaders(true),
        }
      );
    } catch (error) {
      throw errorText(error as RequestError);
    }
  },
  downloadCreative(hashid: string): unknown {
    try {
      return callAPI(
        `${process.env.VUE_APP_API_BASE_URL}api/builder/export-to-master/${hashid}`,
        "GET",
        null,
        true
      );
    } catch (e) {
      throw errorText(e as RequestError);
    }
  },
};
