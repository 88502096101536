



























import Vue from "vue";
import gsap from "gsap";
import { bannerMixin } from "@/mixins/bannerMixin";
import TextLayerVue from "@/components/Editor/EditorShapes/TextLayer.vue";
import ImageLayerVue from "@/components/Editor/EditorShapes/ImageLayer.vue";
import CtaLayerVue from "@/components/Editor/EditorShapes/CtaLayer.vue";
import FolderLayerVue from "@/components/Editor/EditorShapes/FolderLayer.vue";
import { rebuildCanvasAnimation } from "@/utils/layersAndPropsHandling";
import { PREVIEW_ID } from "@/typings/Enums";
import { AssetItem, BannerProps, OtomoCanvasState } from "@/typings/Editors";

export default Vue.extend({
  name: "CreativePreview.vue",
  mixins: [bannerMixin],
  components: { TextLayerVue, ImageLayerVue, CtaLayerVue, FolderLayerVue },
  data: () => ({
    theTimeline: gsap.timeline(),
  }),
  props: {
    creativeHashid: String,
  },
  computed: {
    isActive: function () {
      return this.theTimeline.isActive();
    },
  },
  methods: {
    loadCreative: async function () {
      await this.getCreative(this.creativeHashid);
    },
    loadCSSRules: function () {
      const sheet = document.styleSheets[document.styleSheets.length - 1];

      this.state.banner.assets.map((a: AssetItem) => {
        sheet.insertRule(
          `@font-face { font-family:"${a.name.split(".")[0]}"; src:url("${
            a.url
          }") format("${a.name.split(".")[1]}"); }`,
          sheet.cssRules.length
        );
      });
    },
  },
  beforeMount: async function () {
    await this.loadCreative();
  },
  mounted: function () {
    this.$store.watch(
      (state: OtomoCanvasState) => state.banner,
      (value: BannerProps) => {
        gsap.to(PREVIEW_ID, { ...value.properties, overflow: "hidden" });
        this.theTimeline = rebuildCanvasAnimation(value.layers);
        this.theTimeline.restart();
        this.loadCSSRules();
      }
    );
  },
});
