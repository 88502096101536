import gsap from "gsap";
import {
  AssetItem,
  BannerProps,
  CSSProperties,
  KeyframeProp,
  Layer,
  MediaTypeProp,
  OtomoCanvasState,
  TableParams,
  ToastNotification,
} from "@/typings/Editors";
import Vue from "vue";
import Vuex, { createLogger } from "vuex";
import api from "./api";
import {
  deleteLayerDeep,
  deleteKeyframeDeep,
  getLayerLastKF,
  createKeyframe,
  updateKeyframe,
  duplicateLayer,
  updateHoverState,
  updateLayerProps,
  updateLayerListTree,
  updatePropertiesToKeyframe,
  updatePropertiesToAllKeyframe,
} from "../utils/layersAndPropsHandling";
import { resizeLayers } from "../utils/resizeToolCore";
import { Creative } from "@/typings/Editors";
interface AuthResponse {
  token: string;
}

Vue.use(Vuex);
export const GET_BANNER_DEFAULT = (): BannerProps => ({
  assets: [],
  currentTime: 0,
  layers: [],
  name: "New Creative",
  layersCreationCount: 1,
  properties: {
    // display: "flex",
    backgroundColor: "#FFFFFF",
    // backgroundImage: "url()",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    height: "600",
    minHeight: "600px",
    minWidth: "600px",
    overflow: "hidden",
    position: "relative",
    width: "600",
  },
});

export default new Vuex.Store<OtomoCanvasState>({
  plugins: [createLogger()],
  strict: true,
  state: {
    animatedCanvas: undefined,
    animationMode: true,
    banner: { ...GET_BANNER_DEFAULT() },
    banners: {},
    campaign: {},
    campaigns: {},
    clients: {},
    creatives: {},
    isBackgroundImage: false,
    isEditMode: false,
    isMediaLibraryModalOpen: false,
    mediaLibrary: [],
    mediaLibraryModalType: "",
    selectCampaignStep: 1,
    selectedClient: { hashid: "" },
    selectedKeyframe: undefined,
    selectedLayer: undefined,
    toastNotification: undefined,
    token: "",
    user: {},
  },
  mutations: {
    updateSelectCampaignStep(state, step) {
      state.selectCampaignStep = step;
    },
    setUser(state, token) {
      state.token = token.token;
    },
    getProfile(state, user) {
      state.user = user;
    },

    setClients(state, clients) {
      state.clients = clients;

      if (Array.isArray(clients.items) && clients.items.length > 0) {
        clients.items.sort((a: { name: string }, b: { name: string }) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      }
    },

    // setClients(state, clients) {
    //   state.clients = clients;
    // },
    setClient(state, client) {
      state.selectedClient = client;
    },
    setCampaigns(state, campaigns) {
      state.campaigns = campaigns;

      if (Array.isArray(campaigns.items) && campaigns.items.length > 0) {
        campaigns.items.sort(
          (
            a: { dateCreated: string | number | Date },
            b: { dateCreated: string | number | Date }
          ) => {
            const timestampA = new Date(a.dateCreated).getTime();
            const timestampB = new Date(b.dateCreated).getTime();

            return timestampB - timestampA;
          }
        );
      }
    },

    // setCampaigns(state, campaigns) {
    //   state.campaigns = campaigns;
    // },

    setCampaign(state, campaign) {
      state.campaign = campaign;
    },
    saveCreative(state, creative) {
      const creativeResponse = {
        ...creative,
        ...creative.payload,
        layers: creative.payload.layers.elements,
      };
      delete creativeResponse.payload;
      state.banner = creativeResponse;
    },
    setAssetsLibrary(state, assets) {
      state.mediaLibrary = assets.assets;
    },
    uploadAsset(state, value) {
      state.mediaLibrary = [...state.mediaLibrary, value];
    },
    toggleAsset(state, value) {
      const exist = state.banner.assets.findIndex(
        (a: AssetItem) => value.id === a.id
      );
      if (exist !== -1) {
        state.banner.assets = [
          ...state.banner.assets.filter((a: AssetItem) => value.id !== a.id),
        ];
      } else {
        state.banner.assets = [...state.banner.assets, value];
      }
    },
    updateCreative(state, creative) {
      const fonts = creative.payload.layers.fonts || [];
      const creativeResponse = {
        ...creative,
        ...creative.payload,
        layers: creative.payload.layers.elements,
        assets: [...creative.payload.assets, ...fonts],
      };
      delete creativeResponse.payload;
      state.banner = creativeResponse;
    },
    setCreatives(state, creatives) {
      state.creatives = creatives;

      if (Array.isArray(creatives.items) && creatives.items.length > 0) {
        // Sort creatives.items by dateCreated in descending order
        creatives.items.sort(
          (
            a: { dateCreated: string | number | Date },
            b: { dateCreated: string | number | Date }
          ) => {
            // Convert dates to timestamps
            const timestampA = new Date(a.dateCreated).getTime();
            const timestampB = new Date(b.dateCreated).getTime();

            // Compare timestamps in descending order
            return timestampB - timestampA;
          }
        );
      }
    },

    // setCreatives(state, creatives) {
    //   state.creatives = creatives;
    // },
    setCreative(state, creative) {
      const fonts = creative.payload.layers.fonts || [];
      const assets = creative.payload.assets || [];
      const creativeResponse = {
        ...creative,
        ...creative.payload,
        layers: creative.payload.layers.elements,
        assets: [...assets, ...fonts],
      };
      delete creativeResponse.payload;
      state.banner = creativeResponse;
    },
    deleteCreative(state, creativeId) {
      (state.creatives as { items: Creative[] }).items = (
        state.creatives as { items: Creative[] }
      ).items.filter((c: Creative) => c.hashid !== creativeId);
    },
    downloadCreative(state, response) {
      const zipFile = response?.master.Location;
      window.open(zipFile, "_blank");
    },
    updateSelectedClient(state, client) {
      state.selectedClient = client;
    },
    setBannerStyleProperties(state, property) {
      state.banner.properties = {
        ...state.banner.properties,
        [property.key]: property.value,
      };
    },
    setBannerOtomoProperties(state, property) {
      state.banner = {
        ...state.banner,
        [property.key]: property.value,
      };
    },
    resizeBanner(state, { width, height, originalBanner }) {
      const minWidth = width;
      const minHeight = height;
      const newLayers = resizeLayers(
        {
          width: originalBanner.properties.width as number,
          height: originalBanner.properties.height as number,
        },
        { width, height },
        originalBanner.layers
      );

      state.banner = {
        ...originalBanner,
        properties: {
          ...originalBanner.properties,
          width,
          height,
          minWidth,
          minHeight,
        },
        layers: newLayers,
      };
    },
    updateCreativeName(state, name) {
      state.banner = {
        ...state.banner,
        name,
      };
    },
    setSelectedLayer(state, layer) {
      state.selectedLayer = layer;
      if (!layer) {
        state.selectedKeyframe = undefined;
      }

      if (layer && state.selectedKeyframe?.layerId !== layer.id) {
        state.selectedKeyframe = getLayerLastKF(layer);
      }
    },
    duplicateLayer(state, layer: Layer) {
      state = duplicateLayer(state, layer);
    },
    updateLayer(state, layer: Layer) {
      state = updateLayerProps(state, layer);
    },
    updateMode(state, mode) {
      state.animationMode = mode;
    },
    addLayer(state: OtomoCanvasState, newLayer: Layer) {
      // state.banner.layers.push(newLayer);
      state.banner.layers = [...state.banner.layers, newLayer];
      state.selectedLayer = newLayer;
      if (newLayer)
        state.selectedKeyframe =
          newLayer.properties[newLayer.properties.length - 1];
      if (!state.banner.layersCreationCount)
        state.banner.layersCreationCount = state.banner.layers.length;
      state.banner.layersCreationCount = state.banner.layersCreationCount + 1;
    },
    deleteLayer(state: OtomoCanvasState, id: string) {
      state.banner.layers = [...deleteLayerDeep(state.banner.layers, id)];
      if (state.selectedLayer && state.selectedLayer.id === id)
        state.selectedLayer = undefined;
    },
    deleteKeyframe(state: OtomoCanvasState, kf: KeyframeProp) {
      state.banner.layers = [...deleteKeyframeDeep(state.banner.layers, kf)];
      // const layerIndex = state.banner.layers.findIndex(
      //   (l) => l.id === kf.layerId
      // );
      // const layer = { ...state.banner.layers[layerIndex] };
      // const newKeyframesList = layer.properties.filter((k) => k.id !== kf.id);
      // const layers = [...state.banner.layers];

      // layer.properties = [...newKeyframesList];
      // layers[layerIndex] = { ...layer };

      // state.banner.layers = [...layers];
      // state.selectedKeyframe = undefined;
    },
    updateProperties(state: OtomoCanvasState, properties: CSSProperties) {
      state = updatePropertiesToKeyframe(state, properties);
    },
    updatePropertiesToAllKF(
      state: OtomoCanvasState,
      properties: CSSProperties
    ) {
      state = updatePropertiesToAllKeyframe(state, properties);
    },
    createKeyframe(state: OtomoCanvasState, layer: Layer) {
      state = createKeyframe(state, layer);
    },
    updateKeyframe(state: OtomoCanvasState, keyframe: KeyframeProp) {
      state = updateKeyframe(state, keyframe);
      state.selectedKeyframe = keyframe;
    },
    updateHoverState(state: OtomoCanvasState, hover: KeyframeProp) {
      state = { ...updateHoverState(state, hover) };
    },
    updateLayerList(state: OtomoCanvasState, layers: Layer[]) {
      const updatedState = updateLayerListTree(state, layers);

      state.banner.layers = updatedState.banner.layers;
      state.selectedLayer = updatedState.selectedLayer;
      state.selectedKeyframe = updatedState.selectedKeyframe;
    },
    setSelectedKeyframe(
      state: OtomoCanvasState,
      selectedKeyframe: KeyframeProp
    ) {
      state.selectedKeyframe = selectedKeyframe;
    },
    updateAnimatedCanvas(
      state: OtomoCanvasState,
      animatedCanvas: gsap.core.Timeline
    ) {
      state.animatedCanvas = animatedCanvas;
    },
    updateAssetManager(state: OtomoCanvasState, value: MediaTypeProp) {
      state.mediaLibraryModalType = value.mediaType;
      state.isMediaLibraryModalOpen = value.isModalLibraryOpen;
      state.isBackgroundImage = value.isBackgroundImage;
    },
    toggleEditModeOn(state: OtomoCanvasState) {
      state.isEditMode = true;
    },
    toggleEditModeOff(state: OtomoCanvasState) {
      state.isEditMode = false;
    },
    setOtomoToastValues(state: OtomoCanvasState, toast: ToastNotification) {
      state.toastNotification = toast;
    },
    clearToOriginalState(state: OtomoCanvasState) {
      state.banners = {};
      state.campaign = {};
      state.campaigns = {};
      state.clients = {};
      state.creatives = {};
    },
    clearEditorState(state: OtomoCanvasState) {
      state.banner = { ...GET_BANNER_DEFAULT() };
      state.banner.layers = [];
      state.selectedKeyframe = undefined;
      state.selectedLayer = undefined;
    },
  },
  getters: {
    getMode: (state) => state.animationMode,
    clientList: (state) => state.clients,
    getSelectedClient: (state) => state.selectedClient,
    getBannerProperties: (state) => state.banner,
    getLayers: (state) => state.banner.layers,
  },
  actions: {
    updateAssetManager: ({ commit }, value) => {
      return commit("updateAssetManager", value);
    },
    toggleEditModeOn: ({ commit }) => {
      return commit("toggleEditModeOn");
    },
    toggleEditModeOff: ({ commit }) => {
      return commit("toggleEditModeOff");
    },
    setOtomoToastValues: ({ commit }, value) => {
      return commit("setOtomoToastValues", value);
    },
    toggleAsset: ({ commit }, value) => commit("toggleAsset", value),
    updateSelectCampaignStep: ({ commit }, step) =>
      commit("updateSelectCampaignStep", step),
    login: async ({ commit }, data) => {
      const response = (await api.login(data)) as AuthResponse;
      localStorage.setItem("otomo_tk", response.token);
      return commit("setUser", response);
    },
    getProfile: async ({ commit }) => {
      const profileResponse = await api.getProfile();
      return commit("getProfile", profileResponse);
    },
    getClients: async ({ commit }, params: TableParams) => {
      const response = await api.getClients(params);
      return commit("setClients", response);
    },
    getClient: async ({ commit }, clientId) => {
      const response = await api.getClient(clientId);
      return commit("setClient", response);
    },
    setSelectedClient: ({ commit }, client) =>
      commit("updateSelectedClient", client),
    setBannerStyleProperties: ({ commit }, property) =>
      commit("setBannerStyleProperties", property),
    setBannerOtomoProperties: ({ commit }, property) =>
      commit("setBannerOtomoProperties", property),
    resizeBanner: ({ commit }, property) => commit("resizeBanner", property),
    saveCampaign: async ({ state }, campaignName) => {
      const selectedClientId = state.selectedClient?.hashid;
      await api.saveCampaign(selectedClientId, campaignName);
      return;
    },
    deleteCampaign: async (_, campaignHashId) => {
      await api.deleteCampaign(campaignHashId);
      return;
    },
    getCampaigns: async ({ commit }, params: TableParams) => {
      const response = await api.getCampaigns(params);
      return commit("setCampaigns", response);
    },
    getCampaign: async ({ commit }, hashid) => {
      const response = await api.getCampaign(hashid);
      return commit("setCampaign", response);
    },
    getCreatives: async ({ commit }, params: TableParams) => {
      const response = await api.getCreatives(params);
      return commit("setCreatives", response);
    },
    getCreative: async ({ commit }, creativeid) => {
      const response = await api.getCreative(creativeid);
      return commit("setCreative", response);
    },
    saveCreative: async ({ commit }, value) => {
      const response = await api.saveCreative(value);
      return commit("saveCreative", response);
    },
    updateCreative: async ({ commit }, value) => {
      const response = await api.updateCreative(value);
      return commit("updateCreative", response);
    },
    deleteCreative: async ({ commit }, creativeHashId) => {
      await api.deleteCreative(creativeHashId);
      return commit("deleteCreative", creativeHashId);
    },
    downloadCreative: async ({ commit }, creativeHashId) => {
      const response = await api.downloadCreative(creativeHashId);
      return commit("downloadCreative", response);
    },
    getAssetsLibrary: async ({ commit }, value) => {
      const response = await api.getAssetsLibrary(value);
      return commit("setAssetsLibrary", response);
    },
    uploadAsset: async ({ commit }, payload) => {
      let response;
      try {
        response = api
          .uploadAsset(payload.value)
          .then((data) => payload.callback(data));
      } catch (error) {
        console.log(error);
        payload.error(error);
      }
      return commit("uploadAsset", response);
    },
    deleteAsset: async ({ commit }, payload) => {
      let response;
      try {
        response = api
          .deleteAsset(payload.value)
          .then((data) => payload.callback(data));
      } catch (error) {
        console.log(error);
        payload.error(error);
      }
    },
    setSelectedLayer: ({ commit }, layer) => commit("setSelectedLayer", layer),
    updateSelectedLayer: ({ commit }, updates) =>
      commit("updateLayer", updates),
    toggleEditorMode: ({ commit }, value) => commit("updateMode", value),
    addLayer: ({ commit }, value) => commit("addLayer", value),
    createFolder: ({ commit }, layer) => commit("addLayer", layer),
    duplicateLayer: ({ commit }, value) => commit("duplicateLayer", value),
    updateLayer: ({ commit }, value) => commit("updateLayer", value),
    deleteLayer: ({ commit }, value) => commit("deleteLayer", value),
    deleteKeyframe: ({ commit }, value) => commit("deleteKeyframe", value),
    updateLayerList: ({ commit }, value) => commit("updateLayerList", value),
    updateHoverState: ({ commit }, value) => commit("updateHoverState", value),
    updateProperties: ({ commit }, value) => commit("updateProperties", value),
    updatePropertiesToAllKF: ({ commit }, value) =>
      commit("updatePropertiesToAllKF", value),
    createKeyframe: ({ commit }, value) => commit("createKeyframe", value),
    dragLayer: ({ commit }, value) => commit("updateLayerList", value),
    updateCreativeName: ({ commit }, value) =>
      commit("updateCreativeName", value),
    setSelectedKeyframe: ({ commit }, value) =>
      commit("setSelectedKeyframe", value),
    updateKeyframe: ({ commit }, value) => commit("updateKeyframe", value),
    updateAnimatedCanvas: ({ commit }, value) =>
      commit("updateAnimatedCanvas", value),
    clearToOriginalState: ({ commit }) => commit("clearToOriginalState"),
    clearEditorState: ({ commit }) => commit("clearEditorState"),
  },
  modules: {},
});
