




































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { Layer } from "@/typings/Editors";
import NestedLayers from "./NestedLayers.vue";

export default Vue.extend({
  name: "Layers.vue",
  mixins: [bannerMixin],
  components: { NestedLayers },
  props: {
    animationPanel: {
      type: Boolean,
      default: false,
    },
    layerItemClicked: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    titleColor: "#E9ECEF",
    activeIndex: undefined,
    isVisible: true, // Initially visible
  }),
  computed: {
    layerList: {
      get() {
        return [...this.$store.state.banner.layers].reverse();
      },
      set(value: Layer[]) {
        this.$store.commit("updateLayerList", value.reverse());
      },
    },
  },
  methods: {
    // toggleVisibility() {
    //   // Toggle visibility
    //   this.isVisible = !this.isVisible;
    // },
    toggleVisibility() {
      // Toggle visibility
      this.isVisible = !this.isVisible;
      // Emit an event to notify parent component
      this.$emit("visibility-updated", this.isVisible);
    },
  },
});
