

















import Vue from "vue";

export default Vue.extend({
  name: "ButtonInput.vue",
  data: () => ({
    buttonActive: false,
  }),
  props: {
    handleClickIcon: {
      type: Function,
      default: () => null,
    },
    buttonVal: {
      type: Boolean,
      default: () => false,
    },
    sidebarButtonIcon: {
      type: String,
    },
    sidebarText: {
      type: String,
    },
  },
  methods: {
    handleButtonInputActive: function () {
      this.buttonActive = !this.buttonActive;
    },
  },
});
