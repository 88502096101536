import {
  Layer,
  KeyframeProp,
  BannerDimentions,
  CSSProperties,
} from "@/typings/Editors";
import { LayerType } from "@/typings/Enums";

export const calculateNewPositions = (
  { x: updatedCx, y: updatedCy }: CSSProperties,
  { x: originalCx, y: originalCy }: CSSProperties,
  { x: toChangeCx, y: toChangeCy, rotateZ }: CSSProperties
): CSSProperties => {
  const uCx = Number(updatedCx);
  const uCy = Number(updatedCy);
  const oCx = Number(originalCx);
  const oCy = Number(originalCy);
  const tCx = Number(toChangeCx);
  const tCy = Number(toChangeCy);

  let newX = tCx;
  let newY = tCy;

  let xDiff = 0;
  let yDiff = 0;

  if (uCx > oCx) {
    xDiff = Math.round(uCx - oCx);
    newX = tCx + xDiff;
  } else if (uCx < oCx) {
    xDiff = Math.round(oCx - uCx);
    newX = tCx - xDiff;
  }

  if (uCy > oCy) {
    yDiff = Math.round(uCy - oCy);
    newY = tCy + yDiff;
  } else if (uCy < oCy) {
    yDiff = Math.round(oCy - uCy);
    newY = tCy - yDiff;
  }

  return {
    x: newX,
    y: newY,
    transform: getTransform3d(newX, newY, 0, rotateZ as number),
  };
};

export const applyChangesToEveryKF = (
  layer: Layer,
  newProps: CSSProperties
): Layer => {
  if (!layer.properties || layer.properties.length === 0) return layer;

  const updatedLayer = {
    ...layer,
    properties: [
      ...layer.properties.map((k) => {
        return {
          ...k,
          properties: {
            ...k.properties,
            ...newProps,
          },
        };
      }),
    ],
  };

  return updatedLayer;
};

export const applyChangesToAllKeyframes = (
  layer: Layer,
  keyframe: KeyframeProp,
  newProps: CSSProperties
): Layer => {
  if (
    !layer.properties ||
    layer.properties.length === 0 ||
    // by removing this x and y validation
    // other props will be updated to all kf
    !newProps.x ||
    !newProps.y
  )
    return layer;

  const originalProps = { ...keyframe.properties };

  const updatedLayer = {
    ...layer,
    properties: [
      ...layer.properties.map((k) => {
        return {
          ...k,
          properties: {
            ...k.properties,
            ...newProps,
            ...calculateNewPositions(newProps, originalProps, k.properties),
          },
        };
      }),
    ],
  };

  return updatedLayer;
};

const resizeTextLayer = (
  layer: Layer,
  newPercentages: BannerDimentions,
  newSize: BannerDimentions
) => {
  return {
    ...layer,
    properties: [
      ...layer.properties.map((k) => {
        const fontValue =
          typeof k.properties.fontSize === "string"
            ? k.properties.fontSize.split("px")[0]
            : k.properties.fontSize;
        const originalW = k.properties.width;
        const originalH = k.properties.height;
        const resizeCoheficent =
          (newPercentages.width + newPercentages.height) / 2;
        let newCoheficent = resizeCoheficent;

        // Rule #5 Landscape
        if (newPercentages.widthToHeight && newPercentages.widthToHeight > 3)
          newCoheficent = resizeCoheficent * 0.5;

        const newW = Number(originalW) * resizeCoheficent;
        const newH = Number(originalH) * resizeCoheficent;
        const newX = Number(k.properties.x) * newPercentages.width;
        const newY = Number(k.properties.y) * newPercentages.height;
        const newFont = Number(fontValue) * newCoheficent;
        const isReferenced = hasPointOfReference(k.properties);
        const isPercentil = hasPercentilDimensions(k.properties);
        const newPosition =
          !isReferenced && !isPercentil
            ? {
                x: newX,
                y: newY,
                transform: getTransform3d(
                  newX,
                  newY,
                  0,
                  k.properties.rotateZ as number
                ),
              }
            : {};

        const finalProps = preventOutOfBounds(
          {
            ...k.properties,
            fontSize: `${newFont}px`,
            width: isPercentil ? originalW : newW,
            height: isPercentil ? originalH : newH,
            ...newPosition,
          },
          newSize
        );

        return {
          ...k,
          properties: finalProps,
        };
      }),
    ],
  };
};

const resizeImageLayer = (
  layer: Layer,
  newPercentages: BannerDimentions,
  newSize: BannerDimentions
) => {
  return {
    ...layer,
    properties: [
      ...layer.properties.map((k) => {
        const originalW = k.properties.width;
        const originalH = k.properties.height;
        const resizeCoheficent =
          (newPercentages.width + newPercentages.height) / 2;
        const newW = Number(originalW) * resizeCoheficent;
        const newH = Number(originalH) * resizeCoheficent;
        const newX = Number(k.properties.x) * newPercentages.width;
        const newY = Number(k.properties.y) * newPercentages.height;
        const isReferenced = hasPointOfReference(k.properties);
        const isPercentil = hasPercentilDimensions(k.properties);
        const newPosition =
          !isReferenced && !isPercentil
            ? {
                x: newX,
                y: newY,
                transform: getTransform3d(
                  newX,
                  newY,
                  0,
                  k.properties.rotateZ as number
                ),
              }
            : {};

        const finalProps = preventOutOfBounds(
          {
            ...k.properties,
            width: isPercentil ? originalW : newW,
            height: isPercentil ? originalH : newH,
            ...newPosition,
          },
          newSize
        );

        return {
          ...k,
          properties: finalProps,
        };
      }),
    ],
  };
};

const hasPercentilDimensions = (d: CSSProperties): boolean => {
  return (
    isPercentilValue(d.width) ||
    isPercentilValue(d.height) ||
    isPercentilValue(d.x) ||
    isPercentilValue(d.y)
  );
};

const isPercentilValue = (value: unknown): boolean => {
  if (typeof value === "number") return false;
  return typeof value === "string" && value.indexOf("%") !== -1;
};

const resizeFolderLayer = (
  layer: Layer,
  newPercentages: BannerDimentions,
  newSize: BannerDimentions
) => {
  return {
    ...layer,
    properties: [
      ...layer.properties.map((k) => {
        const originalW = k.properties.width;
        const originalH = k.properties.height;
        const resizeCoheficent =
          (newPercentages.width + newPercentages.height) / 2;
        const newW = Number(originalW) * resizeCoheficent;
        const newH = Number(originalH) * resizeCoheficent;
        const newX = Number(k.properties.x) * newPercentages.width;
        const newY = Number(k.properties.y) * newPercentages.height;
        const isReferenced = hasPointOfReference(k.properties);
        const isPercentil = hasPercentilDimensions(k.properties);
        const newPosition =
          !isReferenced && !isPercentil
            ? {
                x: newX,
                y: newY,
                transform: getTransform3d(
                  newX,
                  newY,
                  0,
                  k.properties.rotateZ as number
                ),
              }
            : {};

        const finalProps = preventOutOfBounds(
          {
            ...k.properties,
            width: isPercentil ? originalW : newW,
            height: isPercentil ? originalH : newH,
            ...newPosition,
          },
          newSize
        );

        return {
          ...k,
          properties: finalProps,
        };
      }),
    ],
  };
};

const resizeCtaLayer = (
  layer: Layer,
  newPercentages: BannerDimentions,
  newSize: BannerDimentions
) => {
  return {
    ...layer,
    properties: [
      ...layer.properties.map((k) => {
        const MAX_CTA_WIDTH = 0.8;
        const ONE_SIDE_PADDING = 0.1;
        const newX = Number(k.properties.x) * newPercentages.width;
        const newY = Number(k.properties.y) * newPercentages.height;
        const fontValue =
          typeof k.properties.fontSize === "string"
            ? k.properties.fontSize.split("px")[0]
            : k.properties.fontSize;
        const currentFontSize = Number(fontValue);
        const isReferenced = hasPointOfReference(k.properties);
        const isPercentil = hasPercentilDimensions(k.properties);
        const originalW = k.properties.width;
        const originalH = k.properties.height;
        const resizeCoheficent =
          (newPercentages.width + newPercentages.height) / 2;
        let newCoheficent = resizeCoheficent;
        // Rule #5 Landscape
        if (newPercentages.widthToHeight && newPercentages.widthToHeight > 3)
          newCoheficent = resizeCoheficent * 0.5;
        const ctaWidth = Number(originalW) * newCoheficent;
        const ctaHeight = Number(originalH) * newCoheficent;
        const newBannerWidth = newSize.width;

        const widerThanBanner = newBannerWidth * MAX_CTA_WIDTH <= ctaWidth;
        const widthPercentageReduction = widerThanBanner
          ? newBannerWidth / ctaWidth
          : 1;
        const reducedX = newBannerWidth * ONE_SIDE_PADDING;
        const newCTAX = widerThanBanner ? reducedX : newX;
        const newCTAWidth = widerThanBanner
          ? newBannerWidth * MAX_CTA_WIDTH
          : ctaWidth;
        const newCTAHeight = widerThanBanner
          ? ctaHeight * widthPercentageReduction
          : ctaHeight;

        const newXandYPosition = {
          x: newCTAX,
          y: newY,
        };

        const newPosition =
          !isReferenced && !isPercentil ? newXandYPosition : {};

        const finalProps = preventOutOfBounds(
          {
            ...k.properties,
            width: isPercentil ? originalW : newCTAWidth,
            height: isPercentil ? originalH : newCTAHeight,
            fontSize: currentFontSize * newCoheficent,
            ...newPosition,
          },
          newSize
        );

        return {
          ...k,
          properties: finalProps,
        };
      }),
    ],
  };
};

export const preventOutOfBounds = (
  props: CSSProperties,
  bannerSizes: BannerDimentions
): CSSProperties => {
  const MAX_BANNER_BOUNDS = 0.9;
  const layerWidth = Number(props.width);
  const layerHeight = Number(props.height);
  const layerX = Number(props.x);
  const layerY = Number(props.y);

  // RESIZE ERROR - COMMENTING THIS FOR NOW UNTIL WE AGREE CRASH OF RULES
  // ====================================================================
  // const MAX_BANNER_W = bannerSizes.width * MAX_BANNER_BOUNDS;
  const MAX_BANNER_H = bannerSizes.height * MAX_BANNER_BOUNDS;
  // const BANNER_W_PADDING = (bannerSizes.width - MAX_BANNER_W) / 2;
  const BANNER_H_PADDING = (bannerSizes.height - MAX_BANNER_H) / 2;

  const newLayerWidth = layerWidth;
  let newLayerHeight = layerHeight;
  const newLayerX = layerX;
  let newLayerY = layerY;

  // if (layerWidth > MAX_BANNER_W) {
  //   newLayerWidth = MAX_BANNER_W;
  //   newLayerX = BANNER_W_PADDING;
  // } else if (newLayerWidth + newLayerX > MAX_BANNER_W) {
  //   newLayerX = MAX_BANNER_W - newLayerWidth;
  // }

  if (layerHeight > MAX_BANNER_H) {
    newLayerHeight = MAX_BANNER_H;
    newLayerY = BANNER_H_PADDING;
  } else if (newLayerHeight + newLayerY > MAX_BANNER_H) {
    newLayerY = MAX_BANNER_H - newLayerHeight;
  }

  const finalProps = {
    ...props,
    width: isPercentilValue(props.width) ? props.width : newLayerWidth,
    height: isPercentilValue(props.width) ? props.height : newLayerHeight,
    x: isPercentilValue(props.width) ? props.x : newLayerX,
    y: isPercentilValue(props.width) ? props.y : newLayerY,
    transform: getTransform3d(
      isPercentilValue(props.width) ? props.x : newLayerX,
      isPercentilValue(props.width) ? props.y : newLayerY,
      0,
      props.rotateZ as number
    ),
  };

  return finalProps;
};

export const getTransform3d = (
  x: unknown,
  y: unknown,
  z: unknown,
  rotateZ?: number
): string => {
  let transformString = "translate3d(";

  transformString += typeof x === "number" ? `${Math.floor(x)}px, ` : `${x}, `;
  transformString += typeof y === "number" ? `${Math.floor(y)}px, ` : `${y}, `;
  transformString += typeof z === "number" ? `${Math.floor(z)}px)` : `${z})`;
  if (rotateZ) transformString += `rotate(${rotateZ}deg)`;
  return transformString;
};

export const hasPointOfReference = (props: CSSProperties): boolean => {
  const isTopBottomLeftRight = Boolean(
    props.top || props.bottom || props.left || props.right
  );
  return isTopBottomLeftRight;
};

export const applyNewSizeToKeyframes = (
  layer: Layer,
  newPercentages: BannerDimentions,
  newSize: BannerDimentions
): Layer => {
  if (layer.type === LayerType.Text)
    return resizeTextLayer(layer, newPercentages, newSize);
  if (layer.type === LayerType.Image)
    return resizeImageLayer(layer, newPercentages, newSize);
  if (layer.type === LayerType.Cta)
    return resizeCtaLayer(layer, newPercentages, newSize);
  if (layer.type === LayerType.Folder)
    return resizeFolderLayer(layer, newPercentages, newSize);
  return layer;
};

export const getNewRatio = (
  original: BannerDimentions,
  newSize: BannerDimentions
): BannerDimentions => {
  const horizontalDimentions = newSize.width / original.width;
  const verticalDimentions = newSize.height / original.height;
  const widthToHeight = newSize.width / newSize.height;
  const heightToWidth = newSize.height / newSize.width;

  return {
    width: horizontalDimentions,
    height: verticalDimentions,
    widthToHeight,
    heightToWidth,
  };
};

export const resizeLayers = (
  original: BannerDimentions,
  newSize: BannerDimentions,
  layers: Layer[]
): Layer[] => {
  const newPercentages = getNewRatio(original, newSize);
  return layers.map((l) => applyNewSizeToKeyframes(l, newPercentages, newSize));
};
