















































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";
// import SidebarLock from "./ControlsShapes/SidebarLock.vue";

export default Vue.extend({
  name: "PaddingControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
    // SidebarLock,
  },
});
