



























































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";

export default Vue.extend({
  name: "KeyframeControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
  },
  data: () => ({
    eases: [
      "none",
      "power1",
      "power2",
      "power3",
      "power4",
      "back",
      "elastic",
      "bounce",
      "rough",
      "slow",
      "steps",
      "circ",
      "expo",
      "sine",
    ],
  }),
});
