













































































































































































import Vue from "vue";
import { LayerType, NotificationTypes } from "@/typings/Enums";
import { bannerMixin } from "@/mixins/bannerMixin";
import LoadingBar from "@/components/Editor/LoadingBar.vue";
import VideoPreview from "@/components/Editor/VideoPreview.vue";
import {
  AssetType,
  AssetItem,
  OtomoCanvasState,
  Size,
} from "@/typings/Editors";
import { getDefaultImageProps } from "@/utils/layersAndPropsHandling";

export default Vue.extend({
  name: "AssetsManager.vue",
  mixins: [bannerMixin],
  data: () => ({
    assets: [],
    isDialogOpen: false,
    isAssetActive: false,
    items: [],
    loading: false,
    mediaLibraryTypes: ["font", "image", "video"],
    search: "",
    select: null,
    isLoading: false,
  }),
  components: { LoadingBar, VideoPreview },
  props: {
    buttonText: {
      type: String,
    },
  },
  computed: {
    isMediaLibraryModalOpen: {
      get() {
        return this.$store.state.isMediaLibraryModalOpen;
      },
      set(value) {
        this.$store.commit("updateAssetManager", value);
      },
    },
    filteredList() {
      return this.state.mediaLibrary.filter((asset: AssetType) => {
        return asset.name?.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    mediaTypes() {
      console.log(
        "this.state.mediaLibraryModalType",
        this.state.mediaLibraryModalType
      );
      return this.state.mediaLibraryModalType;
    },
  },
  methods: {
    getAssetsFromLibrary: async function () {
      const campaignId = this.$route.params.campaignid;
      await this.getAssetsLibrary(campaignId);
    },
    uploadAssetsHandler: function () {
      const fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    handleModalClose: function () {
      this.updateAssetManager(false);
    },
    handleImage: function (event: Event) {
      const campaignId = this.$route.params.campaignid;
      const target = event.target as HTMLInputElement;
      if (!target.files || !target.files.length) return;
      const assetToUpload = {
        hashid: campaignId,
        asset: target.files[0],
      };
      this.isLoading = true;
      this.uploadAsset({
        value: assetToUpload,
        callback: () => {
          this.isLoading = false;
          this.getAssetsFromLibrary();
        },
        error: (error: { message: string }) => {
          console.log("error: ", error);
          this.isLoading = false;
          this.setOtomoToastValues({
            type: NotificationTypes.Error,
            message: error.message,
            timeout: 4000,
          });
        },
      });
    },
    selectImage: function (asset: AssetType) {
      const { url, size, type } = asset;
      const value = `url(${url})`;

      if (type === "image") {
        const { width, height } = this.getImageSizesBasedOnBanner(size);
        if (this.state.isBackgroundImage === true) {
          this.setBannerStyleProperties({ value, key: `backgroundImage` });
        } else {
          if (this.state.selectedLayer) {
            this.updateLayer({
              ...this.state.selectedLayer,
              value: url,
            });
            this.updateProperties({
              width,
              height,
              backgroundImage: `url(${url})`,
            });
          } else {
            const layerName = !this.state.banner.layersCreationCount
              ? `image-${this.state.banner.layers.length + 1}`
              : `image-${this.state.banner.layersCreationCount}`;
            this.addLayer({
              type: LayerType.Image,
              id: `img-${new Date().getTime()}`,
              properties: [
                getDefaultImageProps({
                  width,
                  height,
                  backgroundImage: `url(${url})`,
                }),
              ],
              name: layerName,
              value: url,
            });
          }
        }
      }

      if (type === "video") {
        if (this.state.selectedLayer) {
          this.updateLayer({
            ...this.state.selectedLayer,
            value: url,
            objectFit: "cover",
          });
          this.updateProperties({ position: "relative" });
        } else {
          const layerName = !this.state.banner.layersCreationCount
            ? `video-${this.state.banner.layers.length + 1}`
            : `video-${this.state.banner.layersCreationCount}`;
          this.addLayer({
            type: LayerType.Video,
            id: `video-${new Date().getTime()}`,
            properties: [
              {
                value: url,
                objectFit: "cover",
              },
            ],
            name: layerName,
            value: url,
          });
        }
      }

      this.handleModalClose();
    },
    getImageSizesBasedOnBanner: function ({
      width: imgWidth = 0,
      height: imgHeight = 0,
    }: Size) {
      let width = imgWidth;
      let height = imgHeight;

      if (width > this.banner.properties.width) {
        width = this.banner.properties.width;
        height = (this.banner.properties.width / width) * height;
      }

      if (height > this.banner.properties.height) {
        height = this.banner.properties.height;
        width = (this.banner.properties.height / height) * width;
      }
      return { width, height };
    },
    isAssetSelected: function (assetId: string) {
      return (
        this.state.banner.assets.findIndex(
          (a: AssetItem) => assetId === a.id
        ) !== -1
      );
    },
    removeAsset: function (assetId: string) {
      const campaignId = this.$route.params.campaignid;
      const data = {
        hashid: campaignId,
        assetId: assetId,
      };
      this.isLoading = true;
      this.deleteAsset({
        value: data,
        callback: () => {
          this.isLoading = false;
          this.getAssetsFromLibrary();
        },
        error: (error: { message: string }) => {
          console.log("error: ", error);
          this.isLoading = false;
          this.setOtomoToastValues({
            type: NotificationTypes.Error,
            message: error.message,
            timeout: 4000,
          });
        },
      });
    },
    selectFont: function (asset: AssetType) {
      const exist = this.state.banner.assets.findIndex(
        (a: AssetItem) => asset.id === a.id
      );

      this.toggleAsset(asset);
      if (exist === -1) {
        this.setOtomoToastValues({
          type: NotificationTypes.Success,
          message: `${asset.name} added to Creative`,
          timeout: 4001,
        });
      } else {
        this.setOtomoToastValues({
          type: NotificationTypes.Success,
          message: `${asset.name} removed from Creative`,
          timeout: 4000,
        });
      }
    },
  },
  mounted() {
    this.getAssetsFromLibrary();
    this.$store.watch(
      (state: OtomoCanvasState) => state.isMediaLibraryModalOpen,
      (value: boolean) => {
        this.isDialogOpen = value;
      }
    );
    this.filteredList;
    this.mediaTypes;
  },
});
