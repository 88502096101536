













































import Vue from "vue";
import Toolbar from "@/components/Editor/Toolbar.vue";
import DefinedsizeControl from "@/components/Resize/Controls/DefinedsizeControl.vue";
import BannersizeControl from "@/components/Resize/Controls/BannersizeControl.vue";
import SocialsizeControl from "@/components/Resize/Controls/SocialsizeControl.vue";

export default Vue.extend({
  name: "CreativeResize",
  components: {
    Toolbar,
    DefinedsizeControl,
    BannersizeControl,
    SocialsizeControl,
  },
  data: () => ({
    leftResizePanels: [0, 1, 2],
  }),
});
