var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"otomo__editor--sidebar-container mt-0"},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""},model:{value:(_vm.sidebarPanels),callback:function ($$v) {_vm.sidebarPanels=$$v},expression:"sidebarPanels"}},[(!this.state.selectedLayer && 'Frame')?[_c('creative-control'),_c('creative-background-control'),_c('adfonts-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e()]:_vm._e(),(
        this.state.selectedLayer &&
        this.state.selectedKeyframe &&
        this.state.selectedLayer.type === 'Folder'
      )?[_c('layer-info-control'),_c('groupposition-control'),_c('color-control'),_c('transform-control'),_c('padding-control'),_c('margin-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e(),_c('advance-control')]:_vm._e(),(
        this.state.selectedLayer &&
        this.state.selectedKeyframe &&
        this.state.selectedLayer.type === 'Shape'
      )?[_c('layer-info-control'),_c('shape-control'),_c('color-control'),_c('transform-control'),_c('padding-control'),_c('margin-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e()]:_vm._e(),(
        this.state.selectedLayer &&
        this.state.selectedKeyframe &&
        this.state.selectedLayer.type === 'Text'
      )?[_c('layer-info-control'),_c('text-control'),_c('color-control'),_c('transform-control'),_c('padding-control'),_c('margin-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e(),_c('advance-control')]:_vm._e(),(
        this.state.selectedLayer &&
        this.state.selectedKeyframe &&
        this.state.selectedLayer.type === 'Cta'
      )?[_c('layer-info-control'),_c('text-control'),_c('color-control'),_c('transform-control'),_c('padding-control'),_c('margin-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e(),_c('advance-control')]:_vm._e(),(
        this.state.selectedLayer &&
        this.state.selectedKeyframe &&
        this.state.selectedLayer.type === 'Image'
      )?[_c('layer-info-control'),_c('imageassets-control'),_c('imageproperties-control'),_c('padding-control'),_c('margin-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e(),_c('advance-control')]:_vm._e(),(
        this.state.selectedLayer &&
        this.state.selectedKeyframe &&
        this.state.selectedLayer.type === 'Video'
      )?[_c('layer-info-control'),_c('video-assets-control'),_c('video-properties-control'),(this.state.selectedKeyframe)?_c('keyframe-control'):_vm._e(),_c('advance-control')]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }