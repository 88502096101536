var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"otomo__canvas--container",attrs:{"id":"gsap_canvas_wrapper"}},[_c('LoadingBar',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{ref:"gsapCanvasRef",class:this.state.isEditMode ? 'canvas-overflow' : '',attrs:{"id":"gsap_canvas"},on:{"click":function () {
        _vm.setSelectedLayer(null);
        _vm.setSelectedKeyframe(null);
      }}},[_c('div',{staticClass:"rule rule-top"}),_c('div',{staticClass:"rule rule-right"}),_c('div',{staticClass:"rule rule-bottom"}),_c('div',{staticClass:"rule rule-left"}),_vm._l((_vm.layers),function(layer){return [(!layer.hidden && layer.type === "Temporal")?_c('TemporalLayerVue',{key:layer.id,staticClass:"layer",class:{
          active: _vm.state.selectedLayer && layer.id === _vm.state.selectedLayer.id,
        },attrs:{"layer":layer,"isResizeTool":_vm.isResizeTool},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedLayer(layer)}}}):_vm._e(),(!layer.hidden && layer.type === "Text")?_c('TextLayerVue',{key:layer.id,staticClass:"layer",class:{
          active: _vm.state.selectedLayer && layer.id === _vm.state.selectedLayer.id,
        },attrs:{"layer":layer,"isResizeTool":_vm.isResizeTool},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedLayer(layer)}}}):_vm._e(),(!layer.hidden && layer.type === "Image")?_c('ImageLayerVue',{key:layer.id,staticClass:"layer",class:{
          active: _vm.state.selectedLayer && layer.id === _vm.state.selectedLayer.id,
        },attrs:{"layer":layer,"isResizeTool":_vm.isResizeTool},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedLayer(layer)}}}):_vm._e(),(!layer.hidden && layer.type === "Cta")?_c('CtaLayerVue',{key:layer.id,staticClass:"layer",class:{
          active: _vm.state.selectedLayer && layer.id === _vm.state.selectedLayer.id,
        },attrs:{"layer":layer,"isResizeTool":_vm.isResizeTool},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedLayer(layer)}}}):_vm._e(),(!layer.hidden && layer.type === "Video")?_c('VideoLayerVue',{key:layer.id,staticClass:"layer",class:{
          active: _vm.state.selectedLayer && layer.id === _vm.state.selectedLayer.id,
        },attrs:{"layer":layer,"isResizeTool":_vm.isResizeTool},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedLayer(layer)}}}):_vm._e(),(!layer.hidden && layer.type === "Folder")?_c('FolderLayerVue',{key:layer.id,staticClass:"layer",class:{
          active: _vm.state.selectedLayer && layer.id === _vm.state.selectedLayer.id,
        },attrs:{"layer":layer,"isResizeTool":_vm.isResizeTool},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedLayer(layer)}}}):_vm._e()]})],2),_c('div',{attrs:{"id":"frame_image"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }