















































































































































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import { ColorProps } from "@/typings/ColorControl";
import SidebarInput from "./ControlsShapes/SidebarInput.vue";

export default Vue.extend({
  name: "ColorControl.vue",
  mixins: [bannerMixin],
  components: {
    SidebarInput,
  },
  data: () => ({
    colorIsActive: false,
    bgColorIsActive: false,
    colorPickerColor: "#000000",
    // colorPickerBG: this?.state.selectedKeyframe.properties.backgroundColor,
    colorPickerBG: "#000000",
    colorOutlineIsActive: false,
    colorOutlinePickerColor: "#000000",
    outlineSize: "",
    borderStyleItems: ["none", "solid"],
  }),
  methods: {
    showColorPicker: function () {
      this.colorIsActive = !this.colorIsActive;
    },
    showBGPicker: function () {
      this.bgColorIsActive = !this.bgColorIsActive;
    },
    showOutlineColorPicker: function () {
      this.colorOutlineIsActive = !this.colorOutlineIsActive;
      if (this.banner.properties.borderColor) {
        this.colorOutlinePickerColor =
          this.state.selectedKeyframe.properties.borderColor;
      }
    },
    updateOutlineSize: function () {
      this.outlineSize = "1px;";
      this.updateProperties({ borderWidth: this.outlineSize });
    },
    updateOutlineStyle: function (borderStyle: string) {
      this.updateProperties({ borderStyle });
      this.updateOutlineColor(this.colorOutlinePickerColor, false);
    },
    updateOutlineColor: function (
      color: ColorProps,
      updateOutlineStyle = true
    ) {
      if (!this.state.selectedKeyframe.properties.borderStyle) {
        if (updateOutlineStyle) this.updateOutlineStyle("solid");
      }
      if (!this.state.selectedKeyframe.properties.borderWidth) {
        this.updateOutlineSize();
      }

      this.updateProperties({ borderColor: color });
    },
  },
});
