















































import { userMixin } from "@/mixins/userMixin";
import { bannerMixin } from "@/mixins/bannerMixin";
import { NotificationTypes } from "@/typings/Enums";

export default {
  name: "Login",
  mixins: [userMixin, bannerMixin],
  props: {
    source: String,
  },
  data: function (): {
    username: string;
    password: string;
    btnDisabled: boolean;
  } {
    return {
      username: "",
      password: "",
      btnDisabled: false,
    };
  },
  methods: {
    async submitLogin(): Promise<void> {
      try {
        this.btnDisabled = true;
        await this.login({
          username: this.username,
          password: this.password,
          errorHandling: () => {
            this.setOtomoToastValues({
              type: NotificationTypes.Error,
              message: `The username or password are incorrect.`,
              timeout: 4000,
            });
          },
        });

        await this.getProfile();
        await this.$router.push({ path: "/clients" });
      } catch (error) {
        console.log(error);
        this.setOtomoToastValues({
          type: NotificationTypes.Error,
          message: `The username or password are incorrect.`,
          timeout: 4000,
        });
      }
    },
  },
};
