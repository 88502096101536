













import Vue from "vue";
import gsap from "gsap";
import { bannerMixin } from "@/mixins/bannerMixin";

export default Vue.extend({
  name: "PlayerControls.vue",
  mixins: [bannerMixin],
  computed: {
    realTimelineDuration() {
      return this.theTimeline.duration();
    },
  },
  // data: () => ({
  //   scrubber: null,
  // }),
  props: {
    theTimeline: {
      type: gsap.core.Timeline,
      default: gsap.timeline(),
    },
  },
  methods: {
    playButtonClick() {
      this.theTimeline.play();
    },
    restartButtonClick() {
      this.theTimeline.restart();
      this.setSelectedLayer(undefined);
      this.setSelectedKeyframe(undefined);
    },
  },
  // mounted() {
  //   this.$store.watch(
  //     (state: OtomoCanvasState) => state.banner.layers,
  //     (layers: Layer[]) => this.renderTimeline(layers)
  //   );
  // },
});
