
































import Vue from "vue";
import { bannerMixin } from "@/mixins/bannerMixin";
import VideoPreview from "@/components/Editor/VideoPreview.vue";

export default Vue.extend({
  name: "VideoAssetsControl.vue",
  mixins: [bannerMixin],
  components: { VideoPreview },
  data: () => ({
    dialog: false,
  }),
  methods: {
    openAssetManagerHandler: function () {
      this.updateAssetManager({
        mediaType: "video",
        isModalLibraryOpen: true,
      });
    },
  },
});
