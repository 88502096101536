import { mapActions, mapGetters } from "vuex";

export const userMixin = {
  computed: {
    ...mapGetters(["clientList"]),
  },
  methods: {
    ...mapActions({
      login: "login",
      getProfile: "getProfile",
      getClients: "getClients",
      getClient: "getClient",
      setSelectedClient: "setSelectedClient",
    }),
  },
};
