var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"otomo__timeline container container--fluid"},[_c('v-row',{staticClass:"otomo__timeline__scroll-area"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('layers',{attrs:{"isVisible":_vm.isVisible},on:{"visibility-updated":_vm.handleVisibilityUpdate}})],1),(_vm.isVisible)?_c('div',{ref:"otomo__timeline_wrapper",staticClass:"otomo__timeline-wrapper"},[_c('div',{ref:"otomo__timeline__progress",staticClass:"otomo__timeline-seconds",on:{"click":function($event){$event.stopPropagation();return _vm.progressBarClick.apply(null, arguments)}}},[_c('div',{ref:"otomo__timeline__scrubber",staticClass:"otomo__timeline-progress-selector"}),_vm._l((Array(_vm.timelineFramesNumber)),function(item,key){return _c('div',{key:key,staticClass:"otomo__timeline--frames-indicator"},[_c('span',[_vm._v(_vm._s(key + 1))])])})],2),_vm._l((_vm.allLayersAndChildren),function(layer,index){return _c('div',{key:index,staticClass:"otomo__timeline--frames-row",attrs:{"no-gutters":"","id":("otomo__timeline--frames-row-" + (layer.id))}},[_c('div',{staticClass:"otomo__timeline--frame-wrapper"},_vm._l((layer.properties),function(kfItem){return _c('div',{key:("keyframe-item-" + (kfItem.id)),staticClass:"otomo__timeline--frame",class:{
              active: _vm.selectedKeyframe && kfItem.id === _vm.selectedKeyframe.id,
              animated: kfItem.isAnimated,
            },attrs:{"id":("keyframe-item-" + (kfItem.id)),"disabled":layer.locked},on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openContextMenu($event, kfItem)},"click":function($event){$event.stopPropagation();return (function () {
                if (layer.locked) { return; }
                _vm.setSelectedLayer(layer);
                _vm.setSelectedKeyframe(kfItem);
              }).apply(null, arguments)}}},[_c('div',{staticClass:"otomo__timeline-handle otomo__timeline-handle-left"}),_c('div',{staticClass:"otomo__timeline-handle otomo__timeline-handle-right"})])}),0),_vm._l((Array(_vm.timelineFramesNumber)),function(keyframe,kIndex){return _c('div',{key:("keyframe-box-" + kIndex),staticClass:"otomo__timeline--frames-second-box"})})],2)})],2):_vm._e()],1),(_vm.isVisible)?_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3","offset":"3"}},[_c('v-toolbar',{staticClass:"py-0",attrs:{"elevation":"0","height":40}},[_c('v-btn',{staticClass:"otomo__timeline--icon",attrs:{"icon":""},on:{"click":_vm.restartButtonClick}},[_c('v-icon',[_vm._v("mdi-skip-next")])],1),_c('v-btn',{staticClass:"otomo__timeline--icon",attrs:{"icon":""},on:{"click":_vm.playButtonClick}},[_c('v-icon',[_vm._v("mdi-play")])],1),_c('v-btn',{staticClass:"otomo__timeline--icon",attrs:{"icon":""},on:{"click":function($event){return _vm.theTimeline.pause()}}},[_c('v-icon',[_vm._v("mdi-pause")])],1)],1)],1)],1):_vm._e(),_c('vue-simple-context-menu',{ref:"keyframeOptionsContextMenu",attrs:{"element-id":"keyframeContextMenu","options":_vm.keyframeContextMenuOptions},on:{"option-clicked":_vm.keyframeContextMenuOptionClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }